/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import moment from 'moment';

import * as VIEWS from 'constants/views';
import * as PROFILES from 'constants/profiles';
import { PUBLICATION_TYPES_LIST } from 'config/posts';
import autoUpdateKeys from 'utils/autoUpdateKeys';
import { getPeriodByPreset } from 'utils/dateRangeUtil';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'config/app';

const defaultPeriod = 'last3Months';
const { start_at, end_at } = getPeriodByPreset(
  defaultPeriod,
  BACKEND_DATE_FORMAT,
  FRONTEND_DATE_FORMAT,
);

export const initialTrackingLinksState = {
  urlDestination: '',
  kolsIds: [],
  patternType: 'random',
  trackingLinksValid: []
}

export const initialDiscountCodesState = {
  kolsIds: [],
  hasError: false,
  codePromo: null,
  newOrExisting: 'new',
  mapUserExistingCode: {},
  codeName: '',
  patternType: 'firstName',
  prefix: {
    active: false,
    value: '',
  },
  sufix: {
    active: false,
    value: '',
  },
  reviewCodes: null
}

export const initialCampaignsHistorySorters = {
  page: 0,
  per_page: PROFILES.CAMPAGNE_HISTORY_PER_PAGE,
  sort_by: 'name',
  sort_direction: 'asc',
}

export const initialState = {
  campaignsList: {
    byTeam: 'mine',
    byName: '',
    sortBy: 'createdAt',
    byStates: ['draft', 'ready', 'started', 'finished'],
  },
  campaignDetails: {
    id: undefined,
    loading: true,
    paramsLoaded: false,
    castingId: undefined,
    guest: undefined,
    profiles: {
      params: {
        page: 0,
        per_page: 20,
        order_by: 'name',
        order_direction: 'asc',
        total: 0,
        load_more: true,
        // scopes: 'core, snas, tags',
        search: '',
        stats_sharing: false,
        with_contactable_email: false,
        tags: [],
        status: [],
        id: 0,
      },
      filteredProfiles: [],
      selectedProfiles: [],
    },
    compare: {
      selectedProfiles: [],
      states: [],
      display: 'community',
      sortBy: {
        type: 'name',
        network: 'facebook',
        stat: 'communityCount',
        asc: true,
      },
      tagIds: [],
    },
    shopify: {
      selectedProfiles: [],
      newDiscountCodes: initialDiscountCodesState,
    },
    trackingLinks: {
      selectedProfiles: [],
      newTrackingLinks: initialTrackingLinksState,
    },
    reporting: {
      filters: {
        networks: [],
        types: [],
        tags: [],
        profiles: [],
        sortBy: 'most_engaging',
        page: 0,
        content: [],
      },
      profilesSorted: {
        mainTable: {
          by: 'name',
          order: 'ascending',
          ids: [],
        },
        trackinkLinksTable: {
          by: 'name',
          order: 'ascending',
          ids: [],
        },
        discountCodesTable: {
          by: 'name',
          order: 'ascending',
          ids: [],
        },
        affilaeTable: {
          by: 'name',
          order: 'ascending',
          ids: [],
        },
      },
      profilesStats: {},
      globalStats: {},
      trackingLinks: {
        profiles: {},
        total: {},
      },
      discountCodes: {
        profiles: {},
        total: {}
      },
      profileTags: [],
      posts: [],
      totalPosts: 0,
      selectedPosts: [],
    },
    messenger: {
      search: '',
      states: [],
      sortedIds: [],
    },
  },
  favorites: {
    hasSelection: false,
    lists: [],
    total: 0,
    isLoading: true,
    sorter: {
      onlyShowMine: true,
      currentPage: 0,
      perPage: 20,
      sortBy: 'full_name', // ['full_name', 'community_count']
      sortDirection: 'asc', // ['asc', 'desc'] */
      tagsIds: [],
    },
    selectedProfiles: [],
  },
  taggings: {
    hasSelection: false,
    lists: [],
    total: 0,
    isLoading: true,
    sorter: {
      currentPage: 0,
      perPage: 20,
      sortBy: 'full_name', // ['full_name', 'community_count']
      sortDirection: 'asc', // ['asc', 'desc'] */
      tagsIds: [],
    },
  },
  profileDetails: {
    id: null,
    show: false,
    currentTab: 'profile',
    loaders: {},
    bioSearch: null,
    audience: {
      display: 'followers',
      snaId: null,
    },
    performanceFilters: {
      snaId: null,
      date: {
        slice: 'last3Months',
        startAt: moment()
          .startOf('day')
          .subtract(3, 'months')
          .format(),
      },
    },
    stats: {
      date: {
        slice: '3m',
      },
      sna: null,
    },
    contentRelation: {
      focus: null,
      campaignsHistory: {
        rows: [],
        sorters: initialCampaignsHistorySorters,
        total: 0,
      },
    },
    contentFilters: {
      search: "",
      relatedHashtags: [],
      selectedSnas: [],
      date: {
        slice: defaultPeriod,
        from: start_at,
        to: end_at,
      },
      types: PUBLICATION_TYPES_LIST,
      sortBy: 'latest_post',
      page: 0,
    },
    statistics_by_account: undefined,
    topMentionsFilters: {
      network: 'all',
      date: {
        slice: 'lastMonth',
        from: '',
        to: ''
      },
      mentioned: false, // Force "mentionned by"
    },
  },
  tagsManagement: {
    search: '',
  },
  lookalike: {
    total: 0,
    config: {
      profileIds: [],
      network: ['instagram'],
      similarType: 'topic',
      page: 0,
      perPage: 20,
      hasMore: false,
    },
    rows: [],
    selectedKol: [],
  },
  profile: {
    id: null,
    currentTab: 'profile',
    currentSnaId: null,
    performance: {},
  },
};

const reducers = {
  [VIEWS.RESET_CAMPAIGN_DETAILS]: (state) => {
    state.campaignDetails = initialState.campaignDetails;
  },
  [VIEWS.RESET_CAMPAIGN_DETAILS_PROFILES_PARAMS]: (state) => {
    state.campaignDetails.profiles.params = initialState.campaignDetails.profiles.params;
  },
  [VIEWS.HAS_SELECTION]: (state, { payload: { view, hasSelection } }) =>
    update(state, {
      [view]: {
        hasSelection: { $set: hasSelection },
      },
    }),

  [VIEWS.UPDATE]: (state, { payload: { view, ...datas } }) =>
    update(state, {
      [view]: { $apply: autoUpdateKeys(datas) },
    }),

  [VIEWS.UPDATE_KEY]: (state, { payload: { view, key, ...datas } }) => {
    return update(state, {
      [view]: {
        [key]: {
          $apply: autoUpdateKeys(datas),
        },
      },
    })
  },

  [VIEWS.UPDATE_PROFILE_STATS]: (state, { payload: { kolId, data } }) => {
    state.campaignDetails.reporting.profilesStats[kolId] = {
      ...state.campaignDetails.reporting.profilesStats[kolId],
      ...data,
    }
  },

  [VIEWS.UPDATE_PROFILE_DETAILS_ID]: (state, { payload: id }) => {
    state.profileDetails.id = id;
  },
  [VIEWS.LOAD_LOOKALIKE]: (state, { payload: data }) => {
    state.lookalike.total = data.total;
    state.lookalike.rows = data.rows;
    state.lookalike.config.page = 0;
    state.lookalike.config.hasMore = data.total > state.lookalike.config.perPage;
  },
  [VIEWS.UPDATE_LOOKALIKE]: (state, { payload: data }) => {
    state.lookalike.total = data.total;
    state.lookalike.rows = [...state.lookalike.rows, ...data.rows];
    state.lookalike.config.hasMore = state.lookalike.config.page + 1 < Math.ceil(data.total / state.lookalike.config.perPage);
  },
  [VIEWS.UPDATE_LOOKALIKE_CONFIG]: (state, { payload: { configKey, value, datas } }) => {
    if (configKey) state.lookalike.config[configKey] = value;
    if (datas) state.lookalike.config = { ...state.lookalike.config, ...datas }
  },

  [VIEWS.HANDLE_SELECTION]: (state, { payload: { id, type }}) => {
    if (type === 'reset') state.lookalike.selectedKol = [];
    if (type === 'init') state.lookalike.selectedKol = id;
    if (type === 'check' || type === 'add') state.lookalike.selectedKol.push(id);
    if (type === 'uncheck') state.lookalike.selectedKol = state.lookalike.selectedKol.filter(item => item !== id);
    if (type === 'checkedAll') state.lookalike.selectedKol = [...state.lookalike.rows.map(({ id }) => id).filter(Boolean)];
  },
};

export default createReducer(initialState, reducers);
