/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NETWORKS } from 'constants/networks';
import {
  FACEBOOK_TOGGLES,
  INSTAGRAM_TOGGLES,
  PROFILE_TOGGLES,
  TIKTOK_TOGGLES,
} from 'components/search/SearchEngine/modules/tabsByNetwork/config';
import { getPeriodByPreset, getPresetByDateRange } from 'utils/dateRangeUtil';
import { BACKEND_DATE_FORMAT, FNS_BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'config/app';
import { REMOVE_PROFILES_FROM_STORE } from 'constants/profiles';

import {
  launchSearchStandalone,
  fetchSavedSearches,
  launchSearchFromReduxParams,
} from './searchEngine.actions';
import {
  LocationFilter,
  NetworkTabFilterName,
  SearchParams,
  SliceState,
  TemporarilySavedKeys,
} from './searchEngine.types';

import { startLoading, stopLoading } from '../../utils';

const defaultPeriod = 'last3Months';
const { start_at, end_at } = getPeriodByPreset(
  defaultPeriod,
  BACKEND_DATE_FORMAT,
  FRONTEND_DATE_FORMAT,
);

const initialState: SliceState = {
  // Search Params
  id: null,
  terms: '',
  search_scope: 'content',
  researchable_element: 'profile',
  sort: 'relevance',
  required_networks: [],
  network_filters_operator: 'or',
  basic_filters: [],
  profile_genders: [],
  excluded_project_ids: [],
  excluded_list_ids: [],
  excluded_tag_ids: [],
  profile_type: 'all',
  exclude_favorites: false,
  from: start_at,
  to: end_at,
  visual_search: false,

  // Profiles & Network filters
  profile_filters: {},
  instagram_filters: {},
  youtube_filters: {},
  tiktok_filters: {},
  twitter_filters: {},
  facebook_filters: {},
  snapchat_filters: {},

  // Response objects
  public_profiles: [],
  contents: [],
  statistics_by_account: undefined,

  // Pagination
  page: 0,
  per_page: 25,
  total_count: -1, // -1 for placeholder; 0 for no result
  last_page: false,

  // Starting here: Not from API =>
  // Local state
  period: defaultPeriod,
  loading: [],
  temp_state: {},
  saved_searches: [],
  enabled: false, // user is using the new search currently
};

// Reducers
const searchEngineSlice = createSlice({
  name: 'searchEngine',
  initialState,
  reducers: {
    updateKey: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    setDefaultFilters: (_, action) => {
      return {
        ...initialState,
        ...action.payload
      }
    },
    clearAllFilters: (state) => {
      return {
        ...initialState,
        // Do not reset search scope
        search_scope: state.search_scope,
        // Do not reset saved searches
        saved_searches: state.saved_searches,
        enabled: state.enabled,
      };
    },
    clearFiltersForNetwork: (
      state,
      action: PayloadAction<{
        name?: string;
        network: NetworkTabFilterName;
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      if (action.payload.name) {
        if (action.payload.name in initialState[action.payload.network]) {
          state[action.payload.network][action.payload.name] =
            initialState[action.payload.network][action.payload.name];
        } else {
          delete state[action.payload.network][action.payload.name];
        }
      } else {
        state[action.payload.network] = initialState[
          action.payload.network
        ] as any;

        if (action.payload.network === 'profile_filters') {
          state.basic_filters = state.basic_filters.filter(
            (filter) =>
              !PROFILE_TOGGLES.map((toggle) => toggle.name).includes(filter),
          );
          state.profile_type = initialState.profile_type;
          state.exclude_favorites = initialState.exclude_favorites;
          state.excluded_project_ids = initialState.excluded_project_ids;
          state.excluded_list_ids = initialState.excluded_list_ids;
          state.excluded_tag_ids = initialState.excluded_tag_ids;
          state.profile_genders = initialState.profile_genders;
        }
        if (action.payload.network === 'instagram_filters') {
          state.basic_filters = state.basic_filters.filter(
            (filter) =>
              !INSTAGRAM_TOGGLES.map((toggle) => toggle.name).includes(filter),
          );
        }
        if (action.payload.network === 'tiktok_filters') {
          state.basic_filters = state.basic_filters.filter(
            (filter) =>
              !TIKTOK_TOGGLES.map((toggle) => toggle.name).includes(filter),
          );
        }
        if (action.payload.network === 'facebook_filters') {
          state.basic_filters = state.basic_filters.filter(
            (filter) =>
              !FACEBOOK_TOGGLES.map((toggle) => toggle.name).includes(filter),
          );
        }

        const chosenNetwork = action.payload.network.replace(
          '_filters',
          '',
        ) as (typeof NETWORKS)[number];

        if (state.required_networks?.includes(chosenNetwork)) {
          state.required_networks = state.required_networks.filter(
            (network) => network !== chosenNetwork,
          );
        }
      }
    },
    setTermsAndScope: (
      state,
      action: PayloadAction<{
        terms?: SliceState['terms'];
        search_scope?: SliceState['search_scope'];
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      if ('terms' in action.payload && action.payload.terms !== undefined) {
        state.terms = action.payload.terms;
      }
      if (
        'search_scope' in action.payload &&
        action.payload.search_scope !== undefined
      ) {
        state.search_scope = action.payload.search_scope;
      }
    },
    setViewMode: (
      state,
      action: PayloadAction<{
        researchable_element: SliceState['researchable_element'];
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      state.network_filters_operator = 'or';
      state.researchable_element = action.payload.researchable_element;

      // Delete Estimated cost params if content search
      if(action.payload.researchable_element === 'content') {
        ['instagram_filters', 'youtube_filters', 'facebook_filters', 'tiktok_filters', 'twitter_filters'].forEach(networkFilter => {
          delete state[networkFilter]?.reels_estimated_cost;
          delete state[networkFilter]?.stories_estimated_cost;
          delete state[networkFilter]?.posts_estimated_cost;
        });
      }
    },
    setSort: (
      state,
      action: PayloadAction<{
        sort: SliceState['sort'];
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      state.sort = action.payload.sort;
    },
    setPeriod: (
      state,
      action: PayloadAction<{
        period: SliceState['period'];
        from: SliceState['from'];
        to: SliceState['to'];
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      state.period = action.payload.period;
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
    toggleRequiredNetworks: (
      state,
      action: PayloadAction<{
        network: SliceState['required_networks'][number];
      }>,
    ) => {
      state.id = null;
      state.page = 0;
      state.required_networks = state.required_networks.includes(
        action.payload.network,
      )
        ? state.required_networks.filter(
            (network) => network !== action.payload.network,
          )
        : [...state.required_networks, action.payload.network];
    },
    setNetworkFiltersOperator: (
      state,
      action: PayloadAction<SliceState['network_filters_operator']>,
    ) => {
      state.id = null;
      state.network_filters_operator = action.payload;
    },
    setNetworkSimpleRange: (
      state,
      action: PayloadAction<{
        name: string;
        network: Exclude<NetworkTabFilterName, 'profile_filters'>;
        min?: number;
        max?: number;
      }>,
    ) => {
      state.id = null;
      if (
        !action.payload.min &&
        !action.payload.max &&
        Number.isNaN(Number(action.payload.min)) &&
        Number.isNaN(Number(action.payload.max))
      ) {
        delete state[action.payload.network][action.payload.name];
      } else {
        state[action.payload.network][action.payload.name] = {
          type: 'range',
          key: action.payload.name,
          min: action.payload.min,
          max: action.payload.max,
        };
      }
    },
    setNetworkMultipleRange: (
      state,
      action: PayloadAction<{
        name: string;
        network: NetworkTabFilterName;
        selected: Record<string, number | null>;
      }>,
    ) => {
      state.id = null;
      if (Object.keys(action.payload.selected).length === 0) {
        delete state[action.payload.network][action.payload.name];
      } else {
        state[action.payload.network][action.payload.name] = {
          type: 'multiple_range',
          key: action.payload.name,
          filters: Object.entries(action.payload.selected).map(
            ([key, value]) => ({
              value: key,
              min: value,
            }),
          ),
        };
      }
    },
    setNetworkMultipleLocation: (
      state,
      action: PayloadAction<{
        name: string;
        network: NetworkTabFilterName;
        selected: LocationFilter['filters'];
      }>,
    ) => {
      state.id = null;
      if (action.payload.selected.length === 0) {
        delete state[action.payload.network][action.payload.name];
      } else {
        state[action.payload.network][action.payload.name] = {
          type: 'location',
          key: action.payload.name,
          filters: action.payload.selected,
        };
      }
    },
    setNetworkMultiple: (
      state,
      action: PayloadAction<{
        name: string;
        network: NetworkTabFilterName;
        selected: string[];
      }>,
    ) => {
      state.id = null;
      if (action.payload.selected.length === 0) {
        delete state[action.payload.network][action.payload.name];
      } else {
        state[action.payload.network][action.payload.name] = {
          type: 'multiple',
          key: action.payload.name,
          filters: action.payload.selected.map((key) => ({
            value: key,
          })),
        };
      }
    },
    setExcludedCampaigns: (
      state,
      action: PayloadAction<SliceState['excluded_project_ids']>,
    ) => {
      state.id = null;
      state.excluded_project_ids = action.payload;
    },
    setExcludedLists: (
      state,
      action: PayloadAction<SliceState['excluded_list_ids']>,
    ) => {
      state.id = null;
      state.excluded_list_ids = action.payload;
    },
    setExcludedTags: (
      state,
      action: PayloadAction<SliceState['excluded_tag_ids']>,
    ) => {
      state.id = null;
      state.excluded_tag_ids = action.payload;
    },
    toggleBasicFilter: (state, action: PayloadAction<string>) => {
      state.id = null;
      state.basic_filters = state.basic_filters.includes(action.payload)
        ? state.basic_filters.filter((filter) => filter !== action.payload)
        : [...state.basic_filters, action.payload];
    },
    toggleAccountType: (
      state,
      action: PayloadAction<SliceState['profile_type']>,
    ) => {
      state.id = null;
      state.profile_type = action.payload;
    },
    toggleExcludeFavorites: (
      state,
      action: PayloadAction<SliceState['exclude_favorites'] | undefined>,
    ) => {
      state.id = null;
      if (action.payload === undefined) {
        state.exclude_favorites = !state.exclude_favorites;
      } else {
        state.exclude_favorites = action.payload;
      }
    },
    toggleProfileGender: (
      state,
      action: PayloadAction<SliceState['profile_genders'][number]>,
    ) => {
      state.id = null;
      state.profile_genders = state.profile_genders.includes(action.payload)
        ? state.profile_genders.filter((filter) => filter !== action.payload)
        : [...state.profile_genders, action.payload];
    },
    saveCurrentFiltersInTemp: (state) => {
      state.temp_state = TemporarilySavedKeys.reduce(
        (filters, key) => ({ ...filters, [key]: state[key] }),
        {},
      );
    },
    restoreFiltersFromTemp: (state) => {
      Object.assign(state, state.temp_state);
    },
    clearFiltersInTemp: (state) => {
      state.temp_state = {};
    },
    loadSavedSearch: (state, action: PayloadAction<SearchParams>) => {
      Object.assign(state, action.payload);
      state.period = getPresetByDateRange(
        action.payload.from,
        action.payload.to,
        FNS_BACKEND_DATE_FORMAT,
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    // launchSearch
    builder
      .addCase(launchSearchFromReduxParams.pending, (state) => {
        startLoading(state, launchSearchFromReduxParams);
      })
      .addCase(launchSearchFromReduxParams.fulfilled, (state, action) => {
        if (action.payload) {
          state.id = action.payload.research_id;

          // Profiles results
          const kolIds =
            action.payload?.public_profiles?.map((profile) =>
              Number(profile?.core?.id),
            ) || [];
          state.public_profiles = action.payload.page === 0
            ? kolIds
            : [...state.public_profiles, ...kolIds];

          // Contents results
          const contentsPayload = action.payload?.contents || [];
          state.contents = action.payload.page === 0
            ? contentsPayload
            : [ ...state.contents, ...contentsPayload]

          if (action.payload.page === 0 && action.payload.statistics_by_account) {
            state.statistics_by_account = action.payload.statistics_by_account;
          }

          // Pagination
          state.page = action.payload.page;
          state.total_count = action.payload.total_count;
          state.last_page =
            action.payload.page === 1 &&
            action.payload.per_page < action.payload.total_count &&
            action.payload.page + 1 >
              action.payload.total_count / action.payload.per_page;
        }
        stopLoading(state, launchSearchFromReduxParams);
      })
      .addCase(launchSearchFromReduxParams.rejected, (state) => {
        stopLoading(state, launchSearchFromReduxParams);
      });
      // launchSearch
    builder
    .addCase(launchSearchStandalone.pending, (state) => {
      startLoading(state, launchSearchStandalone);
    })
    .addCase(launchSearchStandalone.fulfilled, (state) => {
      stopLoading(state, launchSearchStandalone);
    })
    .addCase(launchSearchStandalone.rejected, (state) => {
      stopLoading(state, launchSearchStandalone);
    });
    // fetchSavedSearches
    builder
      .addCase(fetchSavedSearches.pending, (state) => {
        startLoading(state, fetchSavedSearches);
      })
      .addCase(fetchSavedSearches.fulfilled, (state, action) => {
        state.saved_searches = action.payload.researches || [];
        stopLoading(state, fetchSavedSearches);
      });
    // removeProfileFromStore
    builder.addCase<string, PayloadAction<number[]>>(
      REMOVE_PROFILES_FROM_STORE,
      (state, action) => {
        const newProfileIds = state.public_profiles.filter(
          (profileId) => !action.payload.includes(Number(profileId)),
        );
        const nbRemoved = state.public_profiles?.length - newProfileIds?.length;

        state.public_profiles = newProfileIds;
        state.total_count -= nbRemoved;
      },
    );
  },
});

// action creators
export const { actions } = searchEngineSlice;

export default searchEngineSlice.reducer;
