import _isEqual from 'lodash.isequal';

import { TREE } from 'utils/hooks/useActivitiesIntl';

export const COUNTRIES_KEYS = [
	'BD',
	'BE',
	'BF',
	'BG',
	'BA',
	'BB',
	'WF',
	'BL',
	'BM',
	'BN',
	'BO',
	'BH',
	'BI',
	'BJ',
	'BT',
	'JM',
	'BV',
	'BW',
	'WS',
	'BQ',
	'BR',
	'BS',
	'JE',
	'BY',
	'BZ',
	'RU',
	'RW',
	'RS',
	'TL',
	'RE',
	'TM',
	'TJ',
	'RO',
	'TK',
	'GW',
	'GU',
	'GT',
	'GS',
	'GR',
	'GQ',
	'GP',
	'JP',
	'GY',
	'GG',
	'GF',
	'GE',
	'GD',
	'GB',
	'GA',
	'SV',
	'GN',
	'GM',
	'GL',
	'GI',
	'GH',
	'OM',
	'TN',
	'JO',
	'HR',
	'HT',
	'HU',
	'HK',
	'HN',
	'HM',
	'VE',
	'PR',
	'PS',
	'PW',
	'PT',
	'SJ',
	'PY',
	'IQ',
	'PA',
	'PF',
	'PG',
	'PE',
	'PK',
	'PH',
	'PN',
	'PL',
	'PM',
	'ZM',
	'EH',
	'EE',
	'EG',
	'ZA',
	'EC',
	'IT',
	'VN',
	'SB',
	'ET',
	'SO',
	'ZW',
	'SA',
	'ES',
	'ER',
	'ME',
	'MD',
	'MG',
	'MF',
	'MA',
	'MC',
	'UZ',
	'MM',
	'ML',
	'MO',
	'MN',
	'MH',
	'MK',
	'MU',
	'MT',
	'MW',
	'MV',
	'MQ',
	'MP',
	'MS',
	'MR',
	'IM',
	'UG',
	'TZ',
	'MY',
	'MX',
	'IL',
	'FR',
	'IO',
	'SH',
	'FI',
	'FJ',
	'FK',
	'FM',
	'FO',
	'NI',
	'NL',
	'NO',
	'NA',
	'VU',
	'NC',
	'NE',
	'NF',
	'NG',
	'NZ',
	'NP',
	'NR',
	'NU',
	'CK',
	'XK',
	'CI',
	'CH',
	'CO',
	'CN',
	'CM',
	'CL',
	'CC',
	'CA',
	'CG',
	'CF',
	'CD',
	'CZ',
	'CY',
	'CX',
	'CR',
	'CW',
	'CV',
	'CU',
	'SZ',
	'SY',
	'SX',
	'KG',
	'KE',
	'SS',
	'SR',
	'KI',
	'KH',
	'KN',
	'KM',
	'ST',
	'SK',
	'KR',
	'SI',
	'KP',
	'KW',
	'SN',
	'SM',
	'SL',
	'SC',
	'KZ',
	'KY',
	'SG',
	'SE',
	'SD',
	'DO',
	'DM',
	'DJ',
	'DK',
	'VG',
	'DE',
	'YE',
	'DZ',
	'US',
	'UY',
	'YT',
	'UM',
	'LB',
	'LC',
	'LA',
	'TV',
	'TW',
	'TT',
	'TR',
	'LK',
	'LI',
	'LV',
	'TO',
	'LT',
	'LU',
	'LR',
	'LS',
	'TH',
	'TF',
	'TG',
	'TD',
	'TC',
	'LY',
	'VA',
	'VC',
	'AE',
	'AD',
	'AG',
	'AF',
	'AI',
	'VI',
	'IS',
	'IR',
	'AM',
	'AL',
	'AO',
	'AQ',
	'AS',
	'AR',
	'AU',
	'AT',
	'AW',
	'IN',
	'AX',
	'AZ',
	'IE',
	'ID',
	'UA',
	'QA',
	'MZ',
];

export const INTERESTS_KEYS = [
	'television_and_film',
	'music',
	'shopping_and_retail',
	'coffee_tea_and_beverages',
	'camera_and_photography',
	'clothes_shoes_handbags_and_accessories',
	'beer_wine_and_spirits',
	'sport_and_fitness',
	'electronics_and_computers',
	'gaming',
	'activewear',
	'art_and_design',
	'travel_tourism_and_aviation',
	'business_and_careers',
	'healthcare_and_medicine',
	'jewellery_and_watches',
	'restaurants_food_and_grocery',
	'toys_children_and_baby',
	'wedding',
	'tobacco_and_smoking',
	'pets',
	'healthy_lifestyle',
	'home_decor_furniture_and_garden',
	'friends_family_and_relationships',
	'cars_and_motorbikes',
	'luxury_goods',
	'beauty_and_cosmetics',
];

export const KOL_THEME_KEYS = [
	// 'adult',
	'animals',
	'achitecture_design',
	'beauty',
	'wellness_health',
	'business',
	'culture',
	'education_knowledge',
	'entertainment',
	'family',
	'food_drinks',
	'gaming',
	'humor',
	'lifestyle',
	'creative_hoobies',
	'luxury',
	'mode_accessories',
	'music',
	'nature_environment',
	'news',
	// 'politic',
	'science',
	'social',
	'sport_fitness',
	'tech',
	'vehicules',
	'traval',
	'photography',
	// 'reality_show',
];

export const LANGUAGES_KEYS = [
	{ api: 'fr', intl: 'FR' },  // French
	{ api: 'en', intl: 'EN' },  // English
	{ api: 'de', intl: 'DE' },  // German
	{ api: 'sp', intl: 'ES' },  // Spanish
	{ api: 'po', intl: 'PT' },  // Portuguese
	{ api: 'it', intl: 'IT' },  // Italian
	{ api: 'nl', intl: 'NL' },  // Dutch
	{ api: 'el', intl: 'EL' },  // Greek
	{ api: 'jp', intl: 'JA' },  // Japanese
	{ api: 'zh', intl: 'ZH' },  // Chinese
	{ api: 'ko', intl: 'KO' },  // Korean
	{ api: 'sv', intl: 'SV' },  // Swedish
	{ api: 'no', intl: 'NO' },  // Norwegian
	{ api: 'da', intl: 'DA' },  // Danish
	{ api: 'fi', intl: 'FI' },  // Finnish
];

export const AUDIENCE_LANGUAGES_KEYS = [
	'en',
	'es',
	'pt',
	'ru',
	'ar',
	'fr',
	'it',
	'de',
	'id',
	'fa',
	'tr',
	'ja',
	'pl',
	'th',
	'zh',
	'ko',
	'hi',
	'ms',
	'arz',
	'nl',
	'uk',
	'ne',
	'az',
	'pa',
	'he',
	'sv',
	'gu',
	'ckb',
	'vi',
	'kk',
	'cs',
	'ro',
	'mr',
	'ky',
	'hu',
	'el',
	'ur',
	'fi',
	'no',
	'ca',
	'da',
	'sr',
	'sw',
	'bg',
	'hr',
	'ml',
	'bn',
	'sq',
	'tl',
	'sk',
	'ps',
	'sh',
	'sl',
	'km',
	'ceb',
	'mk',
	'ta',
	'ce',
	'hy',
	'kn',
	'lt',
	'gl',
	'af',
	'et',
	'si',
	'azb',
	'lv',
	'pnb',
	'lo',
	'bs',
	'uz',
	'ka',
	'mzn',
	'am',
	'is',
	'gd',
	'tt',
	'as',
	'ku',
	'sah',
	'my',
	'sd',
	'mn',
	'war',
	'te',
	'tg',
	'ie',
	'jv',
	'so',
	'be',
	'ia',
	'als',
	'fy',
	'nds',
	'gn',
	'jbo',
	'mhr',
	'ug',
	'ba',
	'nap',
	'su',
	'or',
	'mg',
	'min',
	'ilo',
	'bo',
	'io',
	'tk',
	'ht',
	'ga',
	'kw',
	'pam',
	'bh',
	'lez',
	'ast',
	'pms',
	'yue',
	'yi',
	'xh',
	'hsb',
	'sco',
	'lrc',
	'zu',
	'bar',
	'new',
	'cv',
	'xmf',
	'mai',
	'rw',
	'cbk',
	'sa',
	'gv',
	'krc',
	'dv',
	'eml',
	'wuu',
	'bpy',
	'rm',
	'bcl',
	'lmo',
	'sc',
	'scn',
	'mrj',
	'os',
	'kv',
	'li',
	'xal',
	'yo',
	'myv',
	'vep',
	'tyv',
	'diq',
	'gom',
	'bxr',
	'vec',
	'mwl',
	'nah',
	'vls',
	'dsb',
	'pfl',
	'dty',
	'av',
	'co',
	'frr',
	'rue',
	'other',
];

export const AGE_KEYS = [
	'13to17',
	'18to24',
	'25to34',
	'35to44',
	'45to64',
	'65more',
];

export const AGE_KEYS_SNAPCHAT = [
	'13to17',
	'18to24',
	'25to34',
	'35more',
];

export const MAX_AUDIENCE_LOCATIONS = 25;

export const type = ['influencer', 'socialNetworks'];
export const networks = ['instagram', 'youtube', 'tiktok', 'facebook', 'twitter', 'snapchat'];
export const allCommunitySize = ['communityFacebookSize', 'communityInstagramSize', 'communityTiktokSize', 'communityTwitterSize', 'communityYoutubeSize'];

export const advancedSearchFilters = {
	period: {
		type: "select-one",
		datas: ["lastMonth", "last3Months", "last6Months", "lastYear", "all"],
		default: "all",
	},
};

export const adminFilters = {
	state: {
		datas: ["contactableOnly", "communityAccessOnly", "unqualifiedOnly", "showBrands"],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: [],
	},
};

export const configFilters = {
	active: {
		default: true,
		info: 'fake filter to return key in case of no filter',
		mapper: 'active',
	},
	activities: {
		tree: TREE,
		type: "selecttags",
		params: {
			sort: false,
			pinned: ['influencer', 'blogger', 'influencer_global', 'youtuber'],
			collapse: true,
		},
		default: [],
		mapper: 'activities'
	},
	ages: {
		datas: AGE_KEYS,
		type: "toggle-buttons",
		params: {
			sort: false,
		},
		default: [],
		mapper: 'ages',
	},
	credibility: {
		type: 'qualityScore',
		mapper: 'credibility',
		default: 0,
	},
	communityAges: {
		datas: AGE_KEYS,
		type: "selectPercent",
		params: {
			max: 6,
		},
		default: [],
		defaultPercent: '30',
		mapper: 'community_ages',
	},
	communityCountries: {
		datas: COUNTRIES_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'community_localizations',
		isLocation: true,
		zendeskFaqPath: 'articles/360018816299-Comment-choisir-de-mani%C3%A8re-pertinente-le-pourcentage-de-localisation-de-l-audience-en-fonction-de-chaque-pays-'
	},
	accountCountry: {
		datas: COUNTRIES_KEYS,
		type: 'selectPercent',
		params: {
			max: 1,
		},
		default: [],
		mapper: 'account_location',
		isLocation: false,
	},
	communityEngagementRate: {
		mapper: 'community_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	communityGender: {
		datas: ['male', 'female'],
		type: "selectPercent",
		params: {
			max: 1,
		},
		default: [],
		defaultPercent: '50',
		mapper: 'community_genders',
	},
	communityInterests: {
		datas: INTERESTS_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'community_interests',
	},
	communityLanguages: {
		datas: AUDIENCE_LANGUAGES_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
			pinned: ['de', 'fr', 'en', 'es', 'it', 'nl', 'pt']
		},
		default: [],
		defaultPercent: '30',
		mapper: 'community_languages',
	},
	communitySize: {
		mapper: 'community_size',
		default: {
			min: '',
			max: ''
		}
	},
	enlisted: {
		datas: ['mobilized'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: false,
		mapper: 'enlisted',
	},
	excludeFavorite: {
		type: 'checkboxes',
		default: false,
		mapper: 'exclude_favorites'
	},
	arpp_certified: {
		type: 'checkboxes',
		default: false,
		mapper: 'arpp_certified'
	},
	excludeTag: {
		datas: [],
		type: 'selectMultiple',
		default: [],
		mapper: 'exclude_tag_ids'
	},
	genders: {
		datas: ['male', 'female', 'other'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: true,
			column: true,
		},
		default: [],
		mapper: 'genders',
	},
	influencerThemes: {
		datas: KOL_THEME_KEYS,
		type: 'selecttags',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'influencer_themes',
	},
	interests: {
		datas: INTERESTS_KEYS,
		type: 'selecttags',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'interests',
	},
	languages: {
		datas: LANGUAGES_KEYS,
		type: "selecttags",
		params: {
			max: 3,
			sort: false,
			collapse: true,
		},
		default: [],
		mapper: 'langs',
	},
	localisations: {
		type: "selectgmaps",
		params: {
			max: 3,
		},
		default: [],
		mapper: 'localizations',
	},
	period: {
		type: "select-one",
		datas: ["lastMonth", "last3Months", "last6Months", "lastYear", "all"],
		default: "last3Months",
	},
	state: {
		datas: ["contactableOnly", "communityAccessOnly", "unqualifiedOnly", "showBrands"],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: [],
	},
	seedingKol: {
		type: 'checkboxes',
		default: false,
		mapper: 'seeder',
	},
	status: {
		datas: ['approved'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		mapper: 'status',
		default: false,
	},
	views: {
		mapper: 'views',
		default: {
			min: '',
			max: ''
		}
	},
	viewsAverage: {
		mapper: 'views_average',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEngagementRate: {
		mapper: 'reels_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEstimatedImpressions: {
		mapper: 'reels_estimated_impressions',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEstimatedReach: {
		mapper: 'reels_estimated_reach',
		default: {
			min: '',
			max: ''
		}
	},
	reelsPlays: {
		mapper: 'reels_plays',
		default: {
			min: '',
			max: ''
		}
	},
	reelsLikes: {
		mapper: 'reels_likes',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEngagementRate: {
		mapper: 'stories_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEstimatedImpressions: {
		mapper: 'stories_estimated_impressions',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEstimatedReach: {
		mapper: 'stories_estimated_reach',
		default: {
			min: '',
			max: ''
		}
	},
	storiesPlays: {
		mapper: 'stories_plays',
		default: {
			min: '',
			max: ''
		}
	},
	storiesLikes: {
		mapper: 'stories_likes',
		default: {
			min: '',
			max: ''
		}
	},
};

export const allFilters = {
	...adminFilters,
	...advancedSearchFilters
};

export const isDefault = (filter, value) => {
	const f = allFilters[filter];
	if (!f) return !value || value === '' || value?.length === 0;
	switch (f.type) {
		case "selecttags":
		case 'checkboxes':
		case 'toggle-buttons':
			return value?.length === 0;
		case 'community-sna-size':
			return _isEqual(value, f.default);
		case "input":
		case "select":
		default:
			return value === '' || value === f.default;
	}
};

export const sortBy = ['communitySize', 'commitment', 'mostRecent', 'relevance'];
export const dateSlices = ['lastMonth', 'last3Months', 'last6Months', 'lastYear', 'all'];

export const defaultFilters = {
	activeFilter: [
		"instagram",
		"youtube",
		"tiktok",
		"facebook",
		"twitter"
	],
	socialNetworks: {
		"instagram": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityInterests": [],
			"communityCountries": [],
			"communityLanguages": [],
			"communityAges": [],
			"communityGender": []
		},
		"youtube": {
			"active": true,
			"accountCountry": [],
			"communitySize": {
				"min": "",
				"max": ""
			},
			"viewsAverage": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityCountries": [],
			"communityAges": [],
			"communityGender": [],
			"communityLanguages": [],
		},
		"tiktok": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityCountries": [],
			"communityLanguages": [],
			"communityAges": [],
			"communityGender": []
		},
		"facebook": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			}
		},
		"twitter": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			}
		}
	}
}
