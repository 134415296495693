export const INFLUENCE_CORE = 'influence/unicore';
export const SEARCH_ENGINE = 'influence/search';
export const FAVORITES = 'influence/favorites';
export const LABELS = 'influence/tags';
export const NOTES = 'influence/notes';
export const MANAGE_CAMPAIGN = 'influence/manage_campaigns';
export const MANAGE_CAMPAIGNS_REPORT = 'influence/multi_campaigns_report';
export const MANAGE_FOLDERS = 'influence/folders';
export const LISTENING = 'influence/listening';
export const PAYMENT = 'influence/payment';
