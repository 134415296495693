import _flatten from 'lodash.flatten';
import _uniqBy from 'lodash.uniqby';
import _orderBy from 'lodash.orderby';
import _get from 'lodash.get';

import currencies from 'config/currencies';
import { USER_ROLES } from 'constants/states';

import * as mapperCampaigns from './campaigns';
import * as mapperProfiles from './profiles';

export const checkIfHasAffilaeConnection = {
	fromApi: ({ current_user }) => current_user.has_affilae_connection
}

export const mapStatuses = statuses => {
	if (!statuses) return [];

	return _orderBy(statuses, 'position').map((status, i) => ({
		id : status.id,
		name : status.name,
		position : i
	}));
}

export const getUserParams = {
	fromApi: ({
		current_user,
		features = [],
		default_role,
		licences
	}) => {
		return {
			locale: current_user?.locale,
			timezone: current_user?.timezone,
			dateFormat:current_user?.date_format,
			initialWsData: {
				banner_message: current_user?.banner_message,
				notifications: current_user?.notifications,
			},
			currency: {
				...current_user?.currency,
				...currencies.find(curr => curr.value === current_user?.currency?.value),
			},
			hasTeam: !!current_user?.team_id,
			profile: {
				id: String(current_user?.id),
				teamId: current_user?.team_id ? String(current_user?.team_id) : null,
				teamName: current_user?.team_name,
				firstName: current_user?.first_name,
				lastName: current_user?.last_name,
				phone: current_user?.phone?.startsWith('+') ? current_user.phone.substring(1) : current_user.phone ,
				companyName: _get(current_user, 'salesforce_account.name', ""),
				createdAt: current_user?.created_at,
				admin: current_user?.is_admin,
				canUpdatePhrase: current_user?.can_update_phrase,
				userRole: current_user?.role,
				accountType: current_user?.account_type,
				picture: current_user?.picture_url,
				email: current_user?.email,
				otp_required_for_login: current_user?.otp_required_for_login || false,
				otp_method: current_user?.otp_method || '',
				company: current_user?.salesforce_account ? {
					id: current_user?.salesforce_account.id,
					sfId: current_user?.salesforce_account.sf_id,
					name: current_user?.salesforce_account.name,
				} : null,
				teamMembers: _get(current_user, 'team_members', []).map(member => ({
					id: member.id,
					firstName: member?.first_name,
					lastName: member?.last_name,
					isActive: _get(member, 'is_active', false),
				})),
				zendeskTags: current_user.zendesk_chat_tags || [],
				terms_of_use_acceptation_required: current_user?.terms_of_use_acceptation_required,
				licences: licences || [],
			},
			role: default_role || USER_ROLES.OWNER,	// No role defined, user is certainly not in a team, so he has owner rights
			features: {
				isFree: !features.includes('influence/unicore'),
				core: features.includes('influence/unicore'),
				extension: features.includes('extension/unicore'),
				favorites: features.includes('influence/favorites'),
				manageCampaigns: features.includes('influence/manage_campaigns'),
				manageListenings: features.includes('influence/listening'),
				manageFollowersIncrease: features.includes('influence/followers_increase'),
				manageOverlap: features.includes('influence/audience_overlap'),
				manageFolders: features.includes('influence/folders'),
				tags: features.includes('influence/tags'),
				searchEngine: features.includes('influence/search'),
				onlyManageCampaigns: features.includes('influence/manage_campaigns') && !features.includes('influence/search'),
				onlySearchEngine:
					features.includes('influence/search') &&
					!features.includes('influence/manage_campaigns') &&
					!features.includes('influence/tags') &&
					!features.includes('influence/favorites'),
				manageMyInfluencers:
					features.includes('influence/tags') ||
					features.includes('influence/favorites') ||
					(features.includes('influence/manage_campaigns') && features.includes('influence/search')),
				manageNotes: features.includes('influence/notes'),
				featuresList: features
			},
			tags: (current_user.tags || []).map(tag => ({
				id: String(tag.id),
				value: tag.value,
			})),
			has_affilae_connection: current_user?.has_affilae_connection || false,
			hasMonitorings: current_user?.monitorings || false,
			sendingMethod: current_user?.email_sender || 'kolsquare',
			unacknowledgedFeatures: current_user?.unacknowledged_features || [],
			hideGmailConnect: current_user?.has_gmail_connect_hidden || false,
			isFromKolsquare: /^[a-zA-Z0-9_.\-+]+@(kolsquare\.com|brandandcelebrities\.com)$/.test(current_user.email),
		}
	}
};

export const getUserLicence = {
	fromApi: data => {
		return {
			id: _get( data, 'id', null ),
			days: _get( data, 'days', null ),
			startDate: _get( data, 'start_date', '' ),
			endDate: _get( data, 'end_date', '' ),
			isActive: _get( data, 'is_active', false ),
			isModerate: _get( data, 'is_moderate', false ),
			remainingDaytime: new Date(_get(data, 'remaining_daytime', 0) * 1000)
			  .toISOString()
			  .substring(11, 16),
			usedDays: _get( data, 'used_days', null ),
			shouldDisplayNps: _get( data, 'should_display_nps', false ),
			canExport: _get( data, 'can_export', false ),
		}
	}
};

export const userPreferences = {
  fromApi: data => ({
    sendingMethod: _get(data, 'email_sender', ''),
    hideGmailConnect: _get(data, 'has_gmail_connect_hidden', false),
    unacknowledgedFeatures: _get(data, 'unacknowledged_features', []),
    cmsPreference: _get(data, 'cms_preference', ''),
  }),
  toApi: ({
    sendingMethod,
    hideGmailConnect,
    unacknowledgedFeatures,
    cmsPreference,
  }) => ({
    preferences: Object.assign(
      {},
      sendingMethod && { email_sender: sendingMethod },
      hideGmailConnect && { has_gmail_connect_hidden: hideGmailConnect },
      unacknowledgedFeatures && { unacknowledged_features: unacknowledgedFeatures },
      cmsPreference && { cms_preference: cmsPreference },
    ),
  }),
};

export const getProjects = {
	fromApi: projects => mapperCampaigns.mapCampaigns.fromApi(projects)
};

export const getProfilesFromProjects = {
	fromApi: projects => {
		const profilesByProject = (projects || []).map(project => (project.profiles || []).map(mapperProfiles.mapProfile.fromApi))
		return _uniqBy(_flatten(profilesByProject), 'id');
	}
};

export const updateInfo = {
	toApi: ({ firstName, lastName, phone }) => ({
		first_name: firstName,
		last_name: lastName,
		phone,
	})
};

export const updatePassword = {
	toApi: ({ password, newPassword, passwordConfirmation }) => ({
		current_password: password || '',
		password: newPassword || '',
		password_confirmation: passwordConfirmation || ''
	})
};

export const updateAreaSettings = {
	toApi: ({ locale, timezone, currency }) => Object.assign({},
		locale && { locale },
		timezone && { timezone },
		currency && { currency },
	)
};

export const getClients = {
	toApi: search => ({ search }),
	fromApi: clients => (clients || []).map(client => ({
		id: String(client.id),
		firstName: client.first_name,
		lastName: client.last_name,
		phone: client.phone,
		companyName: client.company_name,
		accountType: client.account_type,
	}))
};

export const getTags = {
	fromApi: tags => tags?.map(({ id, value, kols_count }) => ({
		id: String(id),
		value,
		kols_count
	})),
};

export const updateTag = {
	toApi: tag => ({ tag })
};

export const addTag = {
	toApi: ({ tag }) => ({ tag }),
	fromApi: ({ id }) => id.toString(),
};
