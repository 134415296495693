import React, { useEffect, useMemo, Suspense, lazy } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { unstable_HistoryRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';

import Loader from 'kolkit/Loader';
import useTranslations, { projectIds } from 'utils/hooks/useTranslations';
import { useSelector } from 'utils/redux';
import { conf } from 'config/env';
import { useDateFnsLocale } from 'utils/date-fns';

import { ActionCableProvider } from 'config/actioncable';
import IntlGlobalSingleton from 'utils/HOCs/IntlGlobalSingleton';

import history from 'config/history';
import routes from 'config/routes';
import theme from 'config/theme';

import MainContainer from 'components/MainContainer'
import AuthComponent from 'components/Auth'

import CookiesApprobation from 'components/modules/CookiesApprobation';

const PublicPaneMonitor = lazy(() => import('components/campaigns/details/PublicPaneMonitor'));
const PublicCastingValidation = lazy(() => import('components/campaigns/details/PublicCastingValidation/PublicCastingValidation'));
const PublicContentValidation = lazy(() => import('components/campaigns/details/PublicContentValidation/PublicContentValidation'));
const RegisterHubspot = lazy(() => import('components/Auth/components/Register/RegisterHubspot'));


LicenseInfo.setLicenseKey(conf.muiDatepicker);

const App = () => {
  const { messages } = useTranslations();

  const { user, timeZone, locale, hasInternet } = useSelector(({ env, user }) => ({
    timeZone: env.timezone,
    locale: env.locale,
    user: user?.profile,
    hasInternet: env?.hasInternet,
  }));

  const dateFnsLocale = useDateFnsLocale();

  const localeIso2 = useMemo(
    () => {
      if (!locale) return 'en';
      const initLocal = locale.slice(0, 2);
      return initLocal.startsWith('fr') ? initLocal : 'en';
    },
    [locale]
  );

  useEffect(
    () => {
      if (user?.canUpdatePhrase) initializePhraseAppEditor({
        projectId: projectIds.influence,
        accountId: conf.phraseAccountId,
        phraseEnabled: true,
        autoLowercase: false,
        fullReparse: true, // uses Virtual DOM. This allows proper reparsing when DOM changes are detected
      });
    },
  [user?.canUpdatePhrase]
  );

  return (
    <IntlProvider
      messages={messages}
      locale={localeIso2}
      defaultLocale="en"
      timeZone={timeZone}
      onError={(error) => messages ? console.warn(error) : null}
    >
      <IntlGlobalSingleton />
      <CookiesApprobation />
      <GoogleOAuthProvider clientId={conf.gOAuthClientId}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ActionCableProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={dateFnsLocale}
              >
                <Suspense fallback={<Loader />}>
                  <Router history={history}>
                    <Routes>
                      <Route
                        path={`/${routes.login}`}
                        element={<AuthComponent />}
                      />
                      <Route
                        path={`/${routes.register}`}
                        element={<RegisterHubspot />}
                      />
                      <Route
                        path={`/${routes.publicReport}/:token`}
                        element={<PublicPaneMonitor />}
                      />
                      <Route
                        path={`/${routes.publicCasting}/:token`}
                        element={<PublicCastingValidation />}
                      />
                      <Route
                        path={`/${routes.publicContentValidation}/:token`}
                        element={<PublicContentValidation />}
                      />
                      <Route path="*" element={
                        <MainContainer
                          user={user}
                          locale={locale}
                          hasInternet={hasInternet}
                        />
                      } />
                    </Routes>
                  </Router>
                </Suspense>
              </LocalizationProvider>
            </ActionCableProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </IntlProvider>
  );
};

export default App;
