import { createAction } from 'redux-actions';
import _uniq from 'lodash.uniq';

import * as PROFILES from 'constants/profiles';
import { SNAPCHAT, TIK_TOK } from 'constants/networks';

import * as api from 'api/profiles';
import * as mapper from 'mappers/profiles';
import * as views from 'actions/views';
import * as viewValidator from 'validators/views';

import { getCategoryEmails, getUserProjects } from 'actions/user';
import { setLoader, setControlledLoader } from 'actions/ui';
import {
  getAccountCreationRequests,
  updateAccountRequestParams,
  updateRows,
  updateCustomListAction,
  getAggregate,
  getProfilesList,
} from 'actions/myInfluencers';
import { update as updateProfile } from 'actions/profile';

import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import { planhatTraker } from 'utils/tags';
import ProfileNotFoundError from 'utils/errors/ProfileNotFoundError';
import { getDefaultAudienceTypeByNetwork } from 'utils/networkAudience';
import { loadDataKol } from 'slices/kols.slice';


export const update = createAction(PROFILES.UPDATE);
export const batch = createAction(PROFILES.BATCH);
export const updateSnas = createAction(PROFILES.UPDATE_SNAS);
export const updateSna = createAction(PROFILES.UPDATE_SNA);
export const loadCampaigns = createAction(PROFILES.LOAD_CAMPAIGNS);
export const loadLists = createAction(PROFILES.LOAD_LISTS);
export const loadListsToProfiles = createAction(PROFILES.LOAD_LISTS_TO_PROFILES);
export const addCampaign = createAction(PROFILES.ADD_CAMPAIGN);
export const updateProfileList = createAction(PROFILES.UPDATE_LIST);
export const deleteCampaign = createAction(PROFILES.DELETE_CAMPAIGN);
export const removeProfilesFromStore = createAction(PROFILES.REMOVE_PROFILES_FROM_STORE);

export const updateEmails = createAction(PROFILES.UPDATE_EMAILS);

export const loadProfileCore = (id) => async (dispatch) => {
  if (!id) return null;
  try {
    const res = await api.loadProfileCore(id);
    if (!res || res.error) {
      throw new ProfileNotFoundError(id);
    }
    const coreAndSnas = mapper.loadProfileCore.fromApi(res);
    const result = {
      id,
      ...coreAndSnas,
    };

    dispatch(update(result));
    return result;
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

export const fetchProfilesContactInfosByIds =
  (profileIds) => async (dispatch) => {
    if (!profileIds?.length) return null;

    try {
      const response = await api.loadContactableEmailsBatch({ profileIds });
      if (!response.error) {
        const updatedProfiles = Object.entries(response).map(
          ([profileId, emails]) => {
            const primaryEmail = emails?.find((email) => email?.is_primary);

            return {
              id: profileId,
              contact: emails,
              core: {
                isContactable: Boolean(primaryEmail),
                contactableEmail: primaryEmail?.email,
              },
            };
          },
        );

        dispatch(batch(updatedProfiles));
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

export const loadProfileCoreAndAudience = ({ id }) => async (dispatch) => {
  if (!id) return null;

  try {
    const res = await api.loadProfileCore(id);
    if (!res || res.error) {
      throw new ProfileNotFoundError(id);
    }
    const core = mapper.loadProfileCore.fromApi(res);
    const audience = mapper.loadProfileAudience.fromApi(res);

    const result = {
      id,
      audience,
      ...core,
    };

    dispatch(update(result));

    const firstTiktokSnaId = (res.snas || []).find(
      (sna) => sna.provider === TIK_TOK,
    )?.id;
    const firstSnapchatSnaId = (res.snas || []).find(
      (sna) => sna.provider === SNAPCHAT,
    )?.id;

    const snaId =
      res.primary_sna_id ||
      firstTiktokSnaId ||
      firstSnapchatSnaId ||
      res.snas[0]?.id;

    const network = (res.snas || []).find((sna) => sna.id === snaId)?.provider;

    dispatch(
      views.updateKey({
        view: 'profileDetails',
        key: 'audience',
        snaId,
        display: getDefaultAudienceTypeByNetwork(network),
      }),
    );

    return result;
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

export const loadProfileAbout = ({ id, ...query }) => async (dispatch, getState) => {
  const { profiles } = getState();
  const profile = profiles[id];

  if (!id) return null;

  const res = await api.loadProfileAbout({
    id,
    debug: {
      snasIds: Object.keys(profile?.snas || {}),
    },
    ...query,
  });

  const {snas, ...about} = mapper.loadProfileAbout.fromApi(res);
  const snasWithBio = snas.filter(sna => sna.biography).map(sna => sna.id);

  dispatch(update({
    id,
    about: {
      ...about
    },
    snasWithBio,
  }));

  // Update SNAS
  dispatch(updateSnas({
    id,
    snas
  }))
};

export const loadProfileSnasEmv = id => async (dispatch, getState) => {
  const { profiles } = getState();
  const profile = profiles[id];

  const res = await api.loadProfileSnasEmv({
    id,
    debug: {
      snasIds: Object.keys(profile.snas),
    }
  });
  const snas = mapper.loadProfileSnasEmv.fromApi(res);

  dispatch(updateSnas({
    id,
    snas
  }));
}

export const loadProfileStats = profileId => async (dispatch, getState) => {
  const { views: { profileDetails: { id } }, profiles } = getState();

  const kolId = id || profileId;

  let profile = profiles?.[kolId];

  dispatch(setControlledLoader({ id: 'loadStats', show: true }));

  if (!profile) {
    await dispatch(loadProfileCore(kolId)).then(
      (loadedProfile) => {
        profile = loadedProfile;
      },
      (e) => {
        dispatch(setControlledLoader({ id: 'loadStats', show: false }));
        return Promise.reject(e);
      },
    );
  }

  const res = await api.loadProfileStats({ id: kolId, debug: {} });

  const stats = mapper.loadProfileStats.fromApi(res);

  const snas = Object.keys(profile?.snas || []).map(sna => {
    const stat =
      stats.find(
        (s) =>
          !!profile?.snas?.[sna]?.id &&
          Number(s.id) === Number(profile.snas[sna].id),
      ) || {};
    return {
      ...profile.snas[sna],
      stats: stat
    }
  });

  await dispatch(updateSnas({ id: kolId, snas }));

  dispatch(setControlledLoader({ id: 'loadStats', show: false }));
}

export const loadProfileTopHashtags = id => async dispatch => {
  const res = await api.loadProfileTopHashtags(id);
  const topHashtags = mapper.loadProfileTopHashtags.fromApi(res);

  dispatch(update({
    id,
    topHashtags
  }));
}

export const loadProfileNote = id => async dispatch => {
  const res = await api.loadProfileNote(id);
  const note = mapper.loadProfileNote.fromApi(res);

  dispatch(update({
    id,
    note
  }));
}

export const loadProfileCampaigns = id => async dispatch => {
  const ids = await api.loadProfileCampaignsIds(id);
  const idsMapped = mapper.loadProfileCampaignsIds.fromAPi(ids);

  if (idsMapped?.length) {
    dispatch(loadCampaigns({
      id,
      campaignsIds: idsMapped,
    }));
  }
}

export const loadProfileCampaignsHistory = ({ profileId, sorters = {} }) => async (dispatch, getState) => {
  const {
    id,
    contentRelation: { campaignsHistory }
  } = getState()?.views?.profileDetails;

  dispatch(setControlledLoader({ id: 'campaignHistory', show: true }));

  const res = await api.loadProfileCampaignsHistory({
    id: id || profileId,
    sorters: {
      ...campaignsHistory?.sorters,
      ...sorters
    }
  });

  const mappedRows = mapper.loadProfileCampaignsHistory.fromApi(res?.rows || []);

  dispatch(views.updateKey({
    view: 'profileDetails',
    key: 'contentRelation',
    campaignsHistory: {
      ...campaignsHistory,
      total: res?.total,
      rows: mappedRows,
    }
  }));

  dispatch(setControlledLoader({ id: 'campaignHistory', show: false }));

  return true;
}

export const updateCampaignsHistorySorters = (sorters) => (dispatch, getState) => {
  const { campaignsHistory } = getState()?.views?.profileDetails?.contentRelation;

  dispatch(views.updateKey({
    view: 'profileDetails',
    key: 'contentRelation',
    campaignsHistory: {
      ...campaignsHistory,
      sorters,
    }
  }));
}

export const getTags = (id) => async (dispatch) => {
  const res = await api.getTags(id);
  if (!res?.error) {
    const tagsIds = mapper.getTags.fromApi(res || []);
    dispatch(
      update({
        id,
        tags: tagsIds,
      }),
    );
  } else {
    console.error('Failed to fetch tags: ', res);
  }
};

export const addTagToProfiles = ({ ids, tagId }) => async (dispatch, getState) => {
  const { profiles } = getState();

  const updatedProfiles = ids.map(id => ({ id, tags: [tagId, ...profiles[id].tags] }))
  dispatch(batch(updatedProfiles))
  await api.updateProfilesTags({ ids, tags: [tagId] })
  return true;
}

// DUPLICATE-> GOAL IS TO REMOVE addTagToProfiles by this one name more explicit
export const addTagsToProfiles = ({ ids, tagId }) => async (dispatch, getState) => {
  const { profiles } = getState();

  await api.updateProfilesTags({ ids, tags: [tagId] });

  const updatedProfiles = ids.map(id => ({ id, tags: [tagId, ...profiles[id].tags] }));
  dispatch(batch(updatedProfiles))

  return true;
}

export const addTagToProfile = ({id, tagId}) => async (dispatch, getState) => {
  const { profiles } = getState();

  dispatch(update({ id, tags: [tagId, ...profiles[id].tags]}))

  return true;
}

export const removeTag = ({ id, tagId }) => async (dispatch, getState) => {
  const { profiles } = getState();

  const res = await api.removeTag({ id, tagId });

  if (res.error) return res;

  const profile = profiles?.[id];
  // Update profiles store
  if (profile) {
    dispatch(update({
      id,
      tags: profile.tags.filter(tag => Number(tag) !== Number(tagId))
    }));
  }

  return true;
}

export const updateTags = ({ profileIds, tagsIds }) => async (dispatch, getState) => {
  const { profiles } = getState();

  const getTagsIds = [tagsIds].flat();

  const manyProfiles =  profileIds?.length > 1
  const obj = { tags: getTagsIds }

  if (manyProfiles) {
    await api.updateProfilesTags({ ids: profileIds, ...obj })
  } else {
    await api.updateProfileTags({ id: profileIds[0], ...obj });
  }

  const profilesBatched = profileIds.map(id => {
    const getTags = [profiles?.[id]?.tags].flat().filter(Boolean);
    return ({
      id, tags: _uniq([...getTags, ...getTagsIds])
    })
  });

  dispatch(batch(profilesBatched));
}

export const getTopMentions = ({ id, filters }) => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'topMentions', show: true }));
  const res = await api.getTopMentions({ id, filters });

  if (!res?.error) {
    const topMentions = mapper.getTopMentions.fromApi(res || []);
    dispatch(
      update({
        id,
        topMentions,
      }),
    );
  } else {
    console.error('Error fetching traking links: ', res);
  }

  dispatch(setControlledLoader({ id: 'topMentions', show: false }));

  return true
};

export const saveContactableEmail = ({ profileId, email }) => async dispatch => {
  dispatch(setLoader('setEmail'));

  const res = await api.saveContactableEmail({ profileId, email });

  dispatch(update({
    id: profileId,
    contactableEmail: email,
    core: { isContactable: res.contactable }
  }));

  dispatch(setLoader('setEmail'));
}

/** *******
 *
 * MANAGE EMAILS CONTACT
 */

export const loadContactableEmails =
  (profileId) => async (dispatch, getState) => {
    const isAdmin = getState().user.profile.admin || false;
    if (isAdmin) return null;

    const res = await api.loadContactableEmails(profileId);
    if (!res.error) {
      const primaryEmail = res?.find((contact) => contact?.is_primary);
      dispatch(
        update({
          id: profileId,
          contact: res,
          core: {
            isContactable: Boolean(primaryEmail),
            contactableEmail: primaryEmail?.email,
          },
        }),
      );
    }
  };

export const createContactableEmail = ({ profileId, email, category }) => async dispatch => {
  dispatch(setLoader('addEmail'));
  const data = await api.createContactableEmails({ profileId, email, category });

  dispatch(updateEmails({ profileId, data }));

  dispatch(update({
    id: profileId,
    lastContactId: data?.id,
  }));

  await dispatch(getCategoryEmails());
  dispatch(setLoader('addEmail'));

  return data?.id;
}

export const editContactableEmail = ({ profileId, emailId, email, category }) => async dispatch => {
  if (emailId === 0 || !emailId) return null;

  const data = await api.editContactableEmails({ profileId, email, emailId, category });

  dispatch(updateEmails({ profileId, emailId, data }));

  dispatch(update({
    id: profileId,
    core: { isContactable: true }
  }));
}

export const deleteContactableEmail = ({ profileId, emailId }) => async dispatch => {
  if (emailId === 0) return null;

  await api.deleteContactableEmails({ profileId, emailId });

  dispatch(loadContactableEmails(profileId));
}

export const selectContactableEmails = ({ profileId, emailId }) => async (dispatch, getState) => {
  const { views: { profileDetails }, profile } = getState();
  dispatch(setLoader('setEmail'));

  const res = await api.selectContactableEmails({ profileId, emailId });

  const getEmail = res?.find(contact => contact?.is_primary)?.email;

  dispatch(update({
    id: profileId,
    contact: res,
    core: {
      isContactable: true,
      contactableEmail: getEmail,
    }
  }));

  if (profileDetails.show) {
    dispatch(updateProfile({
      key: 'profile',
      value: {
        ...profile.profile,
        email: getEmail
      }
    }))

    dispatch(updateProfile({
      key: 'collaboration',
      value: {
        ...profile.collaboration,
        contactable_email: getEmail
      }
    }))
  }

  dispatch(setLoader('setEmail'));
}


/** *******
 *
 * MANAGE PROFILE DETAILS
 */

export const loadProfileDetails = (profileId) => async (dispatch, getState) => {
  const isAdmin = getState().user.profile.admin || false;
  if (isAdmin) return null;

  const res = await api.getProfileDetails(profileId);
  const details = mapper.getProfileDetails.fromApi(res);

  dispatch(
    update({
      id: profileId,
      details,
    }),
  );
};

export const updateProfileDetails = (profileId, data) => async (dispatch, getState) => {
  const { views: { profileDetails } } = getState();

  dispatch(setControlledLoader({ id: 'globalLoader', show: true }));

  try {
    const res = await api.updateProfileDetails(profileId, data);

    if (res?.error) return res;

    if (profileDetails.show) {
      dispatch(updateProfile({
        key: 'collaboration',
        data: res,
      }));
    }

    const mappedData = mapper.getProfileDetails.fromApi(res);

    dispatch(
      update({
        id: profileId,
        details: mappedData,
      }),
    );

    if (viewValidator.isMyKolsManagePage()) {
      dispatch(updateRows({
        profileId,
        firstName: mappedData?.firstName,
        lastName: mappedData?.lastName,
        pseudo: mappedData?.pseudo,
        investedBudget: mappedData?.investedBudget,
        investedBudgetCurrency: mappedData?.investedBudgetCurrency,
      }));

      dispatch(getAggregate());
    }
  } catch (e) {
    await Promise.reject(e)
  }

  dispatch(setControlledLoader({ id: 'globalLoader', show: false }));

  return true
};

export const updateProfileAffilaeId = (profileId, affilaeId) => async dispatch => {
  await api.updateProfileDetails(profileId, { affilaeId });
  dispatch(
    update({
      id: profileId,
      affilae: {
        profileId: affilaeId
      }
    })
  );
}

export const suggestInfluencer = query => async dispatch => {
  const result = await api.suggestInfluencer(query);
  if (query.onFinished) {
    query.onFinished(result);
  }
  if (viewValidator.isMyInfluencersPage()) {
    dispatch(updateAccountRequestParams({ page: 0 }));
    dispatch(getAccountCreationRequests());
  }

  return result;
};

export const suggestSnaForKOL = ({ url }) => async (dispatch, getState) => {
    const { id } = getState().views.profileDetails;

    const response = await api.suggestSnaForKOL({ id, url });

    if (response?.error) {
      if (response.payload.error === 'already_on_a_profile') {
        dispatch(
          loadProfileCore(id),
        );
        const profile = await dispatch(
          loadProfileCore(response.payload.profile_id),
        );
        return Promise.reject(
          new Error(response.payload.error, { cause: { profile } }),
        );
      }
      return Promise.reject(new Error(response.payload.error));
    }

    await dispatch(loadProfileCore(id));

    return Promise.resolve();
  };

export const mergeProfiles =
  ({ profile_ids, profileModifications }) =>
  async (dispatch) => {
    const intl = getIntl();
    try {
      // { id: number }
      const response = await api.mergeProfiles({
        profile_ids: profile_ids.map(Number),
        pseudo: profileModifications.pseudo,
        profile_picture_id: Number(profileModifications.profile_picture_id),
      });
      if (response?.error) {
        throw response;
      }
      toast(
        intl.formatMessage(
          { id: 'profiles.merge.success' },
          { count: profile_ids.length },
        ),
        {
          type: 'success',
        },
      );

      // Refresh data in redux
      if (response.id) {
        dispatch(loadProfileCoreAndAudience({ id: response.id }));
        dispatch(loadProfileDetails(response.id));
        dispatch(loadProfileAbout({ id: response.id }));
        dispatch(loadProfileStats(response.id));
        dispatch(loadDataKol({ kolId: response.id, scopes: 'core, details, snas' }));
      }
      // Reload all campaigns
      dispatch(getUserProjects());

      // Remove absorbed profiles from Redux store
      const absorbedProfileIds = profile_ids
        .map(Number)
        .filter((id) => id !== Number(response.id));
      dispatch(removeProfilesFromStore(absorbedProfileIds));

      return Promise.resolve(response);
    } catch (e) {
      toast(intl.formatMessage({ id: 'profiles.merge.error' }), {
        type: 'error',
      });
      console.error(
        'Could not merge profiles with params: ',
        JSON.stringify({ profile_ids, profileModifications }),
      );
      console.error(e);
      throw e;
    }
  };

/* ********
 *
 * LIST
 *
 */

export const getProfileLists = profileId => async dispatch => {
  dispatch(setControlledLoader({ id: 'getProfileLists', show: true }));
  const data = await api.getProfileLists(profileId);
  dispatch(update({ id: profileId, lists: data.list_ids }))
  dispatch(setControlledLoader({ id: 'getProfileLists', show: false }));
}

export const updateProfilesToLists = ({ listIds, profileIds, action, intl }) => async (dispatch) => {
  if (!profileIds?.length) return null;

  dispatch(setControlledLoader({ id: 'updateProfilesToLists', show: true }));

  if (action === 'add') {
    await api.addProfilesToLists({ listIds, profileIds });
    await planhatTraker({
      action: 'add_profiles_to_list',
    })
  }

  if (action === 'remove') {
    await api.removeProfilesToLists({ listIds, profileIds });
    await planhatTraker({
      action: 'remove_profiles_from_list',
    })
  }

  dispatch(updateProfileList({ profileIds, listIds, action }))

  listIds?.forEach(listId => {
    dispatch(updateCustomListAction({
      listId,
      profileIds,
      action,
    }));
  })

  dispatch(getProfilesList());

  if (intl) {
    toast(intl, {
      title: 'Success',
      type: 'success',
    });
  }

  dispatch(setControlledLoader({ id: 'updateProfilesToLists', show: false }));

  return true;
};

export const loadProfileLists = kolId => async dispatch => {
  const { list_ids } = await api.loadProfilelistIds(kolId);

  if (list_ids?.length) {
    dispatch(loadLists({
      kolIds: [kolId],
      listIds: list_ids
    }))
  }

  return true
}

/* ********
 *
 * CUSTOM FIELDS
 *
 */

export const getProfileCustomFields = profileId => async dispatch => {
  dispatch(setControlledLoader({ id: 'getProfileCustomFields', show: true }));
  const fields = await api.getProfileCustomFields(profileId);
  dispatch(update({ id: profileId, fields: mapper.loadCustomFields.fromApi(fields) }))
  dispatch(setControlledLoader({ id: 'getProfileCustomFields', show: false }));
}

export const updateFieldsToProfile = ({ fields, profileId, action, profileName }) => async (dispatch) => {
  if (!profileId) return null;

  dispatch(setControlledLoader({ id: 'updateFieldsToProfile', show: true }));

  let dataFields = {};

  if (action === 'add') {
    dataFields = await api.addFieldsToProfile({ fields, profileId });
  }

  if (action === 'remove') {
    await api.removeFieldsToProfile({ fields, profileId });
  }

  await dispatch(update({
    id: profileId,
    fields: dataFields?.updated,
  }))

  if (viewValidator.isMyKolsManagePage()) {
    const fieldsRow = Object.entries(fields || {})?.reduce( (acc, [id, value]) => {
      return ({
        ...acc,
        [`customField${id}`]: value
      })
    }, {});
    dispatch(updateRows({
      profileId,
      ...fieldsRow
    }));

    dispatch(getAggregate());
  }

  const intl = getIntl();

  toast(
    intl.formatMessage(
      { id: 'profile.customFields.add'},
      { profile_name: profileName?.trim() }
    ),
    {
      title: 'Success',
      type: 'success',
    }
  );

  dispatch(setControlledLoader({ id: 'updateFieldsToProfile', show: false }));

  return dataFields;
};