import { createAction } from 'redux-actions';

import * as UI from 'constants/ui';

import * as actionsCampaigns from './campaigns';

export const setLoading = createAction(UI.LOADING);
export const setLoader = createAction(UI.LOADER);
export const setControlledLoader = createAction(UI.CONTROLLED_LOADER);

export const setDevice = createAction(UI.DEVICE_CHANGED);

export const showModal = createAction(UI.MODAL_SHOW);
export const hideModal = createAction(UI.MODAL_HIDE);
export const updateModal = createAction(UI.MODAL_UPDATE);


export const setApiError = code => dispatch => {
  dispatch(showModal({ id: 'apiError', data: { code } }));
};

/* Modale create new Campaign */
export const promptCreateNewCampaign = ({
  from,
  newCampaignName,
  startDate,
  endDate,
  campaignIdToClone,
  profileIds,
  onCreationModalClose,
}) => (dispatch) => {
  return new Promise(resolve => {
    const onConfirmCampaign = async datas => {
      if (!datas?.created) {
        dispatch(
          updateModal({
            id: 'campaignModalForm',
            datas: {
              loading: false,
            },
          }),
        );
        if (onCreationModalClose) {
          onCreationModalClose({});
        }
        resolve(false);
        return null;
      }

      // Loader in modale
      dispatch(
        updateModal({
          id: 'campaignModalForm',
          datas: {
            loading: true,
          },
        }),
      );

      const createdCampaign = await dispatch(
        actionsCampaigns.createNewCampaign({
          ...datas.created,
          from,
        }),
      );

      if (datas.profileIds) {
        dispatch(
          actionsCampaigns.addProfilesToCampaigns({
            from,
            profileIds: datas.profileIds,
            campaignIds: [createdCampaign.id],
          }),
        );
      }

      dispatch(hideModal({ id: 'campaignModalForm' }));

      if (onCreationModalClose) {
        onCreationModalClose({
          created: createdCampaign,
          autoAdded: !!datas.profileIds,
        });
      }
      resolve(createdCampaign);
    };

    dispatch(
      showModal({
        id: 'campaignModalForm',
        from,
        newCampaignName,
        startDate,
        endDate,
        campaignIdToClone,
        profileIds,
        onConfirmCampaign,
      }),
    );
  });
};

export const delayedAction = (action, delay) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(action);
    }, delay);
  };
};

export const startActionTimeout = (action, timeout) => dispatch => {
  // Manage setTimeout in global app redux action
  let timer;

  const promise1 = new Promise(resolve => {
    timer = setTimeout(
      () => resolve(dispatch(action)),
      timeout,
    );
  });

  Promise
    .race([promise1])
    .finally(() => clearTimeout(timer));

  return true;
}
