import React, { memo, lazy, Suspense } from 'react';
import Loader from 'kolkit/Loader';

const LoadedComponent = lazy(() => import('./Details'));

const LoaderProfile = () => {
  return (
    <Suspense fallback={<Loader full />}>
      <LoadedComponent />
    </Suspense>
  );
};

export default memo(LoaderProfile);
