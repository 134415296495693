import { useEffect, useMemo, useState, useCallback } from 'react';

import { conf, getEnv, DEV_DEBUG } from 'config/env';
import { useSelector } from 'utils/redux';
import useLoading from 'utils/hooks/useLoading';

export const projectIds = {
  influence: 'd726b0b78ab7ec2baf9f5696ea9002c9',
};

const options = {
  mode: 'cors',
  headers: {
    method: 'GET',
    Authorization: `Bearer ${conf.phraseAccessToken}`,
    'Content-Type': 'application/json',
  },
};

const fallback = 'en';
// Phrase API documentation: https://developers.phrase.com/api

const useTranslations = () => {
  const { on: onLoading, off: offLoading } = useLoading('phraseLoader');

  const [locales, setLocales] = useState([
    {
      code: 'en',
    },
    {
      code: 'fr',
    },
    {
      code: 'de',
    },
    {
      code: 'es',
    },
    {
      code: 'it',
    },
  ]);

  const [translations, setTranslations] = useState({});
  const [loadedFromOnline, setLoadedFromOnline] = useState(false);

  const { locale } = useSelector(({ env }) => ({
    locale: env.locale.substring(0, 2),
  }));

  const getAllLocales = useCallback(async () => {
    const { translation } = getEnv();

    const phraseStaticJson = await Promise.all([
      import('locales/phrase/fr-FR.json'),
      import('locales/phrase/en-US.json'),
      import('locales/phrase/de-DE.json'),
      import('locales/phrase/es-ES.json'),
      import('locales/phrase/it-IT.json'),
    ]).then(([phraseFR, phraseEN, phraseDE, phraseES, phraseIT]) => ({
      fr: phraseFR,
      en: phraseEN,
      de: phraseDE,
      es: phraseES,
      it: phraseIT,
    }));

    setTranslations(phraseStaticJson);

    try {
      const response = await fetch(
        `${translation}/${projectIds.influence}/locales`,
        options,
      );
      if (response?.ok) {
        const locales = await response?.json();
        setLocales(locales);
        return locales;
      }
    } catch (e) {
      console.error(
        'Could not load all Phrase locales, using local fallback',
        e,
      );
    }
  }, []);

  const getTranslationsForLocale = useCallback(
    async (locale) => {
      const { translation } = getEnv();
      onLoading();
      try {
        const response = await fetch(
          `${translation}/${projectIds.influence}/locales/${
            locale.id
          }/download?${new URLSearchParams({
            file_format: 'react_simple_json',
          })}`,
          options,
        );
        offLoading();

        if (response?.ok) {
          const data = await response.json();
          return data;
        }
      } catch (e) {
        offLoading();
      }
    },
    [onLoading, offLoading],
  );

  useEffect(() => {
    // Launched once, at start
    // Get all available locales in Phrase project
    (async () => (!DEV_DEBUG ? getAllLocales() : console.info('Local mode')))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      // Find in previously downloaded translations if already there
      const foundTranslation = translations[locale];
      if (!foundTranslation || !loadedFromOnline) {
        // Find current applied locale
        if (!locales.length) return null;

        const currentLocale =
          locales?.find((l) => l.code.substring(0, 2) === locale) ||
          locales?.find((l) => l.code.substring(0, 2) === fallback);

        if (!currentLocale?.id) return null;

        const strings = await getTranslationsForLocale(currentLocale);
        setTranslations((prev) => ({
          ...prev,
          [currentLocale.code.substring(0, 2)]: strings,
        }));
        setLoadedFromOnline(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, locales]);

  const handleTranslations = useMemo(
    () => ({
      messages: translations[locale || fallback],
      locale,
    }),
    [translations, locale],
  );

  return handleTranslations;
};

export default useTranslations;
