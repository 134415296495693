import _get from 'lodash.get';
import { mapSnaStats } from 'mappers/profiles';


const getProfilesList = {
  toApi: ({ page, perPage, sortBy, sortDirection, tagOperator, advancedFilters, contactIds, listId, activatedFilter, name }) => {
    const tagsIds = (advancedFilters || [])
      .filter(filter => filter?.targetData?.target === 'label')
      .map(filter => filter?.valueData?.id);

    const customFields = (advancedFilters || [])
      .filter(filter => filter?.targetData?.target === 'customField')
      .map(filter => {
        const value = filter?.valueData?.value;
        const formattedValue = filter?.targetData?.type === 'number'
          ? String(value)?.replace(',', '.')
          : value

        return {
          [filter?.targetData?.value]: formattedValue,
        }
      });

    return Object.assign(
      {
        sort_by: sortBy,
        sort_direction: sortDirection,
        per_page: perPage,
        page: !page ? 0 : page,
      },
      activatedFilter && tagsIds?.length > 0 ? { tag_ids: tagsIds } : null,
      customFields?.length > 0 && { custom_fields: customFields },
      tagOperator ? { tag_operator: tagOperator } : null,
      !listId && contactIds && contactIds?.length ? { contact_ids: contactIds.flat() } : null,
      listId ? { list_id: listId } : null,
      name ? { name } : null,
    );
  },
  fromApi: ({ total, rows, aggregate_data, fields }) => {
    return ({
      total,
      aggregate: aggregate_data && aggregateData.fromApi({
        aggregate: aggregate_data || {},
        fields
      }),
      rows: rows
        ?.map(item => {
          const snas = (network, target) => item?.snas
            ?.filter(sna => sna?.provider === network)
            ?.map(sna => ({
              id: sna?.id,
              provider: _get(sna, 'provider', ''),
              username: _get(sna, 'username', ''),
              emvPerPost: _get(sna, 'emv_per_post', ''),
              emvPerStory: _get(sna, 'emv_per_story', ''),
              emvPerReel: _get(sna, 'emv_per_reel', ''),
              estimatedCostPerPost: _get(sna, 'emv_range_per_post', []),
              estimatedCostPerStory: _get(sna, 'emv_range_per_story', []),
              estimatedCostPerReel: _get(sna, 'emv_range_per_reel', []),
              engagementPerPost: _get(sna, 'engagement_per_post', ''),
              engagementPerReel: _get(sna, 'engagement_per_reel', ''),
              engagementRatePerPost: _get(sna, 'engagement_rate_per_post', ''),
              engagementRatePerReel: _get(sna, 'engagement_rate_per_reel', ''),
              communityCount: _get(sna, 'community_count', ''),
              url: _get(sna, 'url', ''),

              // https://www.notion.so/kolsquare/My-KOLS-Add-columns-Top-age-gender-et-location-country-2112864a3a6e485fb3a3145becc8b835
              // All top gender keys from api
              topGenderFollowers: {
                key: _get(sna, 'followers_top_gender', null),
                ratio: _get(sna, 'followers_top_gender_ratio', null),
              },
              topGenderLikers: {
                key: _get(sna, 'likers_top_gender', null),
                ratio: _get(sna, 'likers_top_gender_ratio', null),
              },
              topGenderCommenters: {
                key: _get(sna, 'commenters_top_gender', null),
                ratio: _get(sna, 'commenters_top_gender_ratio', null),
              },
              topGenderViews: {
                key: _get(sna, 'views_top_gender', null),
                ratio: _get(sna, 'views_top_gender_ratio', null),
              },
              // All top age keys from api
              topAgeSliceFollowers: {
                key: _get(sna, 'followers_top_age_slice', null),
                ratio: _get(sna, 'followers_top_age_slice_ratio', null),
              },
              topAgeSliceLikers: {
                key: _get(sna, 'likers_top_age_slice', null),
                ratio: _get(sna, 'likers_top_age_slice_ratio', null),
              },
              topAgeSliceCommenters:{
                key:  _get(sna, 'commenters_top_age_slice', null),
                ratio:  _get(sna, 'commenters_top_age_slice_ratio', null),
              },
              topAgeSliceViews: {
                key: _get(sna, 'views_top_age_slice', null),
                ratio: _get(sna, 'views_top_age_slice_ratio', null),
              },
              // All top location keys from api
              topLocationFollowers:{
                key:  _get(sna, 'followers_top_country', null),
                ratio:  _get(sna, 'followers_top_country_ratio', null),
              },
              topLocationLikers: {
                key: _get(sna, 'likers_top_country', null),
                ratio: _get(sna, 'likers_top_country_ratio', null),
              },
              topLocationCommenters: {
                key: _get(sna, 'commenters_top_country', null),
                ratio: _get(sna, 'commenters_top_country_ratio', null),
              },
              topLocationViews: {
                key: _get(sna, 'views_top_country', null),
                ratio: _get(sna, 'views_top_country_ratio', null),
              },
            }))
            ?.map((sna) => ({
              id: sna.id,
              username: sna.username,
              network: sna.provider,
              value: sna[target],
            }));

          const getSnaPropertyToString = (target, key) => item?.snas
            ?.filter(sna => sna?.provider === target)
            ?.map(sna => sna[key])
            ?.filter((_, index) => index === 0)
            ?.toString();

          const getSnaProperty = (target, key) => item?.snas
            ?.filter(sna => sna?.provider === target)
            ?.map(sna => sna[key])
            ?.filter((_, index) => index === 0)
            ?.filter(Boolean)
            ?.flat()

          const initCustomFields = fields?.reduce((acc, field) => {
            return ({
              ...acc,
              [`customField${field?.custom_field_id}`]: _get(item?.custom_fields?.[field.custom_field_id], 'field_value', null)
            })
          }, {})

          return {
            id: item.id,
            core: {
              id: item.id,
              name: item.name,
              username: item.username,
              highlight: item.highlight,
              picture: item.picture_url,
              isFavorite: item.is_favorite,
              hasNote: item.has_note,
              contactable: item.contactable,
              contactableEmail: item.contactable_email || '',
              campaignIds: item.project_ids,
              lists: item.list_ids,
              is_nullified: item?.is_nullified,
              primary_sna_id: item?.primary_sna_id,
              primary_sna_network: item?.primary_sna_network,
              primary_sna_uid: item?.primary_sna_uid,
              primary_sna_username: item?.primary_sna_username,
            },
            firstName: !item?.detail?.first_name ? item.first_name : item?.detail?.first_name,
            lastName: !item?.detail?.last_name ? item.last_name : item?.detail?.last_name,
            pseudo: item?.detail?.pseudo || item?.username || item?.pseudo,
            investedBudget: item?.invested_budget?.amount || null,
            investedBudgetCurrency: item?.invested_budget?.currency || null,
            labels: item.tags,
            communityCredibility: _get(item, 'community_credibility', 0),
            snas: item.snas.map(sna => ({
              id: sna?.id,
              provider: sna?.provider,
              username: sna?.username,
              communityCount: sna?.community_count,
              url: sna?.url,
            })),
            ...initCustomFields,

            youtubeCommunityCount: snas('youtube', 'communityCount'),
            youtubeEmvPerPost: getSnaPropertyToString('youtube', 'emv_per_post'),
            youtubeEstimatedCostPerPost: getSnaProperty('youtube', 'emv_range_per_post'),
            youtubeEngagementPerPost: getSnaPropertyToString('youtube', 'engagement_per_post'),
            youtubeEngagementRatePerPost: getSnaPropertyToString('youtube', 'engagement_rate_per_post'),
            youtubeEstimatedImpressionPerPost: getSnaPropertyToString('youtube', 'impressions_per_post'),
            youtubeCommunityProgressionRate: getSnaPropertyToString('youtube', 'community_progression_rate'),
            youtubeEngagementRateProgression: getSnaPropertyToString('youtube', 'engagement_rate_progression'),

            instagramCommunityCount: snas('instagram', 'communityCount'),
            instagramEmvPerPost: getSnaPropertyToString('instagram', 'emv_per_post'),
            instagramEmvPerReel: getSnaPropertyToString('instagram', 'emv_per_reel'),
            instagramEstimatedCostPerPost: getSnaProperty('instagram', 'emv_range_per_post'),
            instagramEstimatedCostPerStory: getSnaProperty('instagram', 'emv_range_per_story'),
            instagramEstimatedCostPerReel: getSnaProperty('instagram', 'emv_range_per_reel'),
            instagramEngagementPerPost: getSnaPropertyToString('instagram', 'engagement_per_post'),
            instagramEngagementPerReel: getSnaPropertyToString('instagram', 'engagement_per_reel'),
            instagramEngagementRatePerPost: getSnaPropertyToString('instagram', 'engagement_rate_per_post'),
            instagramEngagementRatePerReel: getSnaPropertyToString('instagram', 'engagement_rate_per_reel'),
            instagramEstimatedImpressionPerPost: getSnaPropertyToString('instagram', 'impressions_per_post'),
            instagramEstimatedImpressionPerStory: getSnaPropertyToString('instagram', 'impressions_per_story'),
            instagramEstimatedImpressionPerReel: getSnaPropertyToString('instagram', 'impressions_per_reel'),
            instagramEstimatedReachPerPost: getSnaPropertyToString('instagram', 'reach_per_post'),
            instagramEstimatedReachPerStory: getSnaPropertyToString('instagram', 'reach_per_story'),
            instagramEstimatedReachPerReel: getSnaPropertyToString('instagram', 'reach_per_reel'),
            instagramCommunityProgressionRate: getSnaPropertyToString('instagram', 'community_progression_rate'),
            instagramEngagementRateProgression: getSnaPropertyToString('instagram', 'engagement_rate_progression'),

            tiktokCommunityCount: snas('tiktok', 'communityCount'),
            tiktokEmvPerPost: getSnaPropertyToString('tiktok', 'emv_per_post'),
            tiktokEstimatedCostPerPost: getSnaProperty('tiktok', 'emv_range_per_post'),
            tiktokEngagementPerPost: getSnaPropertyToString('tiktok', 'engagement_per_post'),
            tiktokEngagementRatePerPost: getSnaPropertyToString('tiktok', 'engagement_rate_per_post'),
            tiktokEstimatedImpressionPerPost: getSnaPropertyToString('tiktok', 'impressions_per_post'),
            tiktokCommunityProgressionRate: getSnaPropertyToString('tiktok', 'community_progression_rate'),
            tiktokEngagementRateProgression: getSnaPropertyToString('tiktok', 'engagement_rate_progression'),

            facebookCommunityCount: snas('facebook', 'communityCount'),
            facebookEmvPerPost: getSnaPropertyToString('facebook', 'emv_per_post'),
            facebookEstimatedCostPerPost: getSnaProperty('facebook', 'emv_range_per_post'),
            facebookEngagementPerPost: getSnaPropertyToString('facebook', 'engagement_per_post'),
            facebookEngagementRatePerPost: getSnaPropertyToString('facebook', 'engagement_rate_per_post'),
            facebookEstimatedImpressionPerPost: getSnaPropertyToString('facebook', 'impressions_per_post'),
            facebookCommunityProgressionRate: getSnaPropertyToString('facebook', 'community_progression_rate'),
            facebookEngagementRateProgression: getSnaPropertyToString('facebook', 'engagement_rate_progression'),

            twitterCommunityCount: snas('twitter', 'communityCount'),
            twitterEmvPerPost: getSnaPropertyToString('twitter', 'emv_per_post'),
            twitterEstimatedCostPerPost: getSnaProperty('twitter', 'emv_range_per_post'),
            twitterEngagementPerPost: getSnaPropertyToString('twitter', 'engagement_per_post'),
            twitterEngagementRatePerPost: getSnaPropertyToString('twitter', 'engagement_rate_per_post'),
            twitterEstimatedImpressionPerPost: getSnaPropertyToString('twitter', 'impressions_per_post'),
            twitterCommunityProgressionRate: getSnaPropertyToString('twitter', 'community_progression_rate'),
            twitterEngagementRateProgression: getSnaPropertyToString('twitter', 'engagement_rate_progression'),

            snapchatCommunityCount: snas('snapchat', 'communityCount'),
            snapchatCommunityProgressionRate: getSnaPropertyToString('snapchat', 'community_progression_rate'),
            snapchatViewsperSpotlight: getSnaPropertyToString('snapchat', 'views_per_spotlight'),
            snapchatReachPerSpotlight: getSnaPropertyToString('snapchat', 'reach_per_spotlight'),
            snapchatEngagementPerSpotlight: getSnaPropertyToString('snapchat', 'engagement_per_spotlight'),
            snapchatViewsPerSavedStory: getSnaPropertyToString('snapchat', 'views_per_saved_story'),
            snapchatReachPerSavedStory: getSnaPropertyToString('snapchat', 'reach_per_saved_story'),
            snapchatEngagementPerSavedStory: getSnaPropertyToString('snapchat', 'engagement_per_saved_story'),

            // Top gender, age and location Instagram (followers & likers)
            instagramTopGenderFollowers: snas('instagram', 'topGenderFollowers'),
            instagramTopAgeSliceFollowers: snas('instagram', 'topAgeSliceFollowers'),
            instagramTopLocationFollowers: snas('instagram', 'topLocationFollowers'),
            instagramTopGenderLikers: snas('instagram', 'topGenderLikers'),
            instagramTopAgeSliceLikers: snas('instagram', 'topAgeSliceLikers'),
            instagramTopLocationLikers: snas('instagram', 'topLocationLikers'),

            // Top gender, age and location Youtube (followers & commenters)
            youtubeTopGenderFollowers: snas('youtube', 'topGenderFollowers'),
            youtubeTopAgeSliceFollowers: snas('youtube', 'topAgeSliceFollowers'),
            youtubeTopLocationFollowers: snas('youtube', 'topLocationFollowers'),
            youtubeTopGenderCommenters: snas('youtube', 'topGenderCommenters'),
            youtubeTopAgeSliceCommenters: snas('youtube', 'topAgeSliceCommenters'),
            youtubeTopLocationCommenters: snas('youtube', 'topLocationCommenters'),

            // Top gender, age and location Snapchat (views)
            snapchatTopGenderViews: snas('snapchat', 'topGenderViews'),
            snapchatTopAgeSliceViews: snas('snapchat', 'topAgeSliceViews'),
            snapchatTopLocationViews: snas('snapchat', 'topLocationViews'),

            // Top gender, age and location tiktok (followers)
            tiktokTopGenderFollowers: snas('tiktok', 'topGenderFollowers'),
            tiktokTopAgeSliceFollowers: snas('tiktok', 'topAgeSliceFollowers'),
            tiktokTopLocationFollowers: snas('tiktok', 'topLocationFollowers'),
          }
        }) || [],
    })
  }
}

export const aggregateData = {
  toApi: ({ tagOperator, tagsIds, contactIds, listId, profileIds }) => Object.assign({
      list_id: listId,
      profile_ids: profileIds,
      contact_ids: contactIds,
    },
    tagsIds?.length > 0 ? { tag_ids: tagsIds } : null,
    tagOperator ? { tag_operator: tagOperator } : null,
  ),
  fromApi: ({ aggregate, fields }) => {
    const getSnaAggregate = (sna, key) => aggregate?.[sna]?.[key];
    const getFieldsAggregate = fields
      ?.reduce((acc, field) => {
        return ({
          ...acc,
          [`customField${field?.custom_field_id}`]: _get(aggregate, `custom_field_${field?.custom_field_id}`, null)
        })
      }, {});

    return ({
      firstName: aggregate?.first_name,
      lastName: aggregate?.last_name,
      pseudo: aggregate?.pseudo,
      investedBudget: aggregate?.invested_budget,
      communityCredibility: aggregate?.credibility,

      youtubeCommunityCount: getSnaAggregate('youtube', 'community_count'),
      youtubeEmvPerPost: getSnaAggregate('youtube', 'emv_per_post'),
      youtubeEstimatedCostPerPost: getSnaAggregate('youtube', 'min_emv_per_post') ? {
        count: getSnaAggregate('youtube', 'min_emv_per_post')?.count,
        sumMin: getSnaAggregate('youtube', 'min_emv_per_post')?.sum,
        sumMax: getSnaAggregate('youtube', 'max_emv_per_post')?.sum,
        avgMin: getSnaAggregate('youtube', 'min_emv_per_post')?.avg,
        avgMax: getSnaAggregate('youtube', 'max_emv_per_post')?.avg,
      } : null,

      youtubeEngagementPerPost: getSnaAggregate('youtube', 'engagement_per_post'),
      youtubeEngagementRatePerPost: getSnaAggregate('youtube', 'engagement_rate_per_post'),
      youtubeEstimatedImpressionPerPost: getSnaAggregate('youtube', 'impressions_per_post'),
      youtubeCommunityProgressionRate: getSnaAggregate('youtube', 'community_progression_rate'),
      youtubeEngagementRateProgression: getSnaAggregate('youtube', 'engagement_rate_progression'),

      instagramCommunityCount: getSnaAggregate('instagram', 'community_count'),
      instagramEmvPerPost: getSnaAggregate('instagram', 'emv_per_post'),
      instagramEmvPerReel: getSnaAggregate('instagram', 'emv_per_reel'),
      instagramEstimatedCostPerPost: getSnaAggregate('instagram', 'min_emv_per_post') ? {
        count: getSnaAggregate('instagram', 'min_emv_per_post')?.count,
        sumMin: getSnaAggregate('instagram', 'min_emv_per_post')?.sum,
        sumMax: getSnaAggregate('instagram', 'max_emv_per_post')?.sum,
        avgMin: getSnaAggregate('instagram', 'min_emv_per_post')?.avg,
        avgMax: getSnaAggregate('instagram', 'max_emv_per_post')?.avg,
      } : null,
      instagramEstimatedCostPerStory: getSnaAggregate('instagram', 'min_emv_per_story') ? {
        count: getSnaAggregate('instagram', 'min_emv_per_story')?.count,
        sumMin: getSnaAggregate('instagram', 'min_emv_per_story')?.sum,
        sumMax: getSnaAggregate('instagram', 'max_emv_per_story')?.sum,
        avgMin: getSnaAggregate('instagram', 'min_emv_per_story')?.avg,
        avgMax: getSnaAggregate('instagram', 'max_emv_per_story')?.avg,
      } : null,
      instagramEstimatedCostPerReel: getSnaAggregate('instagram', 'min_emv_per_reel') ? {
        count: getSnaAggregate('instagram', 'min_emv_per_reel')?.count,
        sumMin: getSnaAggregate('instagram', 'min_emv_per_reel')?.sum,
        sumMax: getSnaAggregate('instagram', 'max_emv_per_reel')?.sum,
        avgMin: getSnaAggregate('instagram', 'min_emv_per_reel')?.avg,
        avgMax: getSnaAggregate('instagram', 'max_emv_per_reel')?.avg,
      } : null,
      instagramEngagementPerPost: getSnaAggregate('instagram', 'engagement_per_post'),
      instagramEngagementPerReel: getSnaAggregate('instagram', 'engagement_per_reel'),
      instagramEngagementRatePerPost: getSnaAggregate('instagram', 'engagement_rate_per_post'),
      instagramEngagementRatePerReel: getSnaAggregate('instagram', 'engagement_rate_per_reel'),
      instagramEstimatedImpressionPerPost: getSnaAggregate('instagram', 'impressions_per_post'),
      instagramEstimatedImpressionPerStory: getSnaAggregate('instagram', 'impressions_per_story'),
      instagramEstimatedImpressionPerReel: getSnaAggregate('instagram', 'impressions_per_reel'),
      instagramEstimatedReachPerPost: getSnaAggregate('instagram', 'reach_per_post'),
      instagramEstimatedReachPerStory: getSnaAggregate('instagram', 'reach_per_story'),
      instagramEstimatedReachPerReel: getSnaAggregate('instagram', 'reach_per_reel'),
      instagramCommunityProgressionRate: getSnaAggregate('instagram', 'community_progression_rate'),
      instagramEngagementRateProgression: getSnaAggregate('instagram', 'engagement_rate_progression'),

      tiktokCommunityCount: getSnaAggregate('tiktok', 'community_count'),
      tiktokEmvPerPost: getSnaAggregate('tiktok', 'emv_per_post'),
      tiktokEstimatedCostPerPost: getSnaAggregate('tiktok', 'min_emv_per_post') ? {
        count: getSnaAggregate('tiktok', 'min_emv_per_post')?.count,
        sumMin: getSnaAggregate('tiktok', 'min_emv_per_post')?.sum,
        sumMax: getSnaAggregate('tiktok', 'max_emv_per_post')?.sum,
        avgMin: getSnaAggregate('tiktok', 'min_emv_per_post')?.avg,
        avgMax: getSnaAggregate('tiktok', 'max_emv_per_post')?.avg,
      } : null,
      tiktokEngagementPerPost: getSnaAggregate('tiktok', 'engagement_per_post'),
      tiktokEngagementRatePerPost: getSnaAggregate('tiktok', 'engagement_rate_per_post'),
      tiktokEstimatedImpressionPerPost: getSnaAggregate('tiktok', 'impressions_per_post'),
      tiktokCommunityProgressionRate: getSnaAggregate('tiktok', 'community_progression_rate'),
      tiktokEngagementRateProgression: getSnaAggregate('tiktok', 'engagement_rate_progression'),

      facebookCommunityCount: getSnaAggregate('facebook', 'community_count'),
      facebookEmvPerPost: getSnaAggregate('facebook', 'emv_per_post'),
      facebookEstimatedCostPerPost: getSnaAggregate('facebook', 'min_emv_per_post') ? {
        count: getSnaAggregate('facebook', 'min_emv_per_post')?.count,
        sumMin: getSnaAggregate('facebook', 'min_emv_per_post')?.sum,
        sumMax: getSnaAggregate('facebook', 'max_emv_per_post')?.sum,
        avgMin: getSnaAggregate('facebook', 'min_emv_per_post')?.avg,
        avgMax: getSnaAggregate('facebook', 'max_emv_per_post')?.avg,
      } : null,
      facebookEngagementPerPost: getSnaAggregate('facebook', 'engagement_per_post'),
      facebookEngagementRatePerPost: getSnaAggregate('facebook', 'engagement_rate_per_post'),
      facebookEstimatedImpressionPerPost: getSnaAggregate('facebook', 'impressions_per_post'),
      facebookCommunityProgressionRate: getSnaAggregate('facebook', 'community_progression_rate'),
      facebookEngagementRateProgression: getSnaAggregate('facebook', 'engagement_rate_progression'),

      twitterCommunityCount: getSnaAggregate('twitter', 'community_count'),
      twitterEmvPerPost: getSnaAggregate('twitter', 'emv_per_post'),
      twitterEstimatedCostPerPost: getSnaAggregate('twitter', 'min_emv_per_post') ? {
        count: getSnaAggregate('twitter', 'min_emv_per_post')?.count,
        sumMin: getSnaAggregate('twitter', 'min_emv_per_post')?.sum,
        sumMax: getSnaAggregate('twitter', 'max_emv_per_post')?.sum,
        avgMin: getSnaAggregate('twitter', 'min_emv_per_post')?.avg,
        avgMax: getSnaAggregate('twitter', 'max_emv_per_post')?.avg,
      } : null,
      twitterEngagementPerPost: getSnaAggregate('twitter', 'engagement_per_post'),
      twitterEngagementRatePerPost: getSnaAggregate('twitter', 'engagement_rate_per_post'),
      twitterEstimatedImpressionPerPost: getSnaAggregate('twitter', 'impressions_per_post'),
      twitterCommunityProgressionRate: getSnaAggregate('twitter', 'community_progression_rate'),
      twitterEngagementRateProgression: getSnaAggregate('twitter', 'engagement_rate_progression'),

      snapchatCommunityCount: getSnaAggregate('snapchat', 'community_count'),
      snapchatCommunityProgressionRate: getSnaAggregate('snapchat', 'community_progression_rate'),
      snapchatViewsperSpotlight: getSnaAggregate('snapchat', 'views_per_spotlight'),
      snapchatReachPerSpotlight: getSnaAggregate('snapchat', 'reach_per_spotlight'),
      snapchatEngagementPerSpotlight: getSnaAggregate('snapchat', 'engagement_per_spotlight'),
      snapchatViewsPerSavedStory: getSnaAggregate('snapchat', 'views_per_saved_story'),
      snapchatReachPerSavedStory: getSnaAggregate('snapchat', 'reach_per_saved_story'),
      snapchatEngagementPerSavedStory: getSnaAggregate('snapchat', 'engagement_per_saved_story'),

      ...getFieldsAggregate,
    })
  }
}

const loadLegacyProfiles = {
  fromApi: ({ rows }) => rows?.map(item => {
    return ({
      id: item.id,
      core: {
        pseudo: _get(item, 'pseudo', '') || _get(item, 'username', ''),
        fullName: _get(item, 'name', ''),
        firstName: _get(item, 'first_name', ''),
        lastName: _get(item, 'last_name', ''),
        picture: item.picture_url,
        isFavorite: item.is_favorite,
        hasNote: item.has_note,
        isContactable: item.contactable,
        contactableEmail: item.contactable_email || '',
        uid: item.sna_uid,
        snaId: item.sna_id,
        is_nullified: item.is_nullified,
      },
      tags: item?.tags?.map(({ id }) => id),
      snas: item.snas.reduce((acc, sna) => ({
        ...acc,
        [sna.id]: {
          id: String(sna.id),
          username: sna.username || '',
          pseudo: sna.pseudo,
          isBlocked : sna.is_blocked || false,
          isPrivate: sna.is_private || false,
          isInactive: sna.is_inactive || false,
          isDeleted: sna.is_deleted || false,
          isRestricted: sna.is_restricted || false,
          network: sna.provider,
          stats: mapSnaStats.fromApi(sna),
        }
      }), {}),
      campaigns: {
        loaded: false,
        ids: item.project_ids
      },
      lists: item.list_ids,
      fields: item?.custom_fields,
    })
  }) || [],
}

const getAccountCreationRequests = {
  fromApi: data => ({
    ...data,
    // add custom mapping or casting here if needed, keeping it for later
  })
}

const audienceOverlapStatus = {
  toApi: () => ({
    validate_only: true,
  }),
  fromApi: data => ({
    validated: data?.validated || false,
    remainingAnalyses: data?.remaining_analyses,
    maxAnalyses: data?.max_analyses || 25,
    validatedReason: data?.reason || '',
    iqDataErrors: data?.iq_data_errors?.empty_audience || [],
    updatedBy: data?.updated?.name || '',
    updateAt: data?.updated?.at || null,
    userId: data?.updated?.id || null,
  })
}

const audienceOverlapStatistics = {
  toApi: data => ({
    overlap_analysis: Object.assign(
      {},
      data?.mention && {
        brand_mention: data?.mention,
        draft: true
      }
    ),
  }),
  fromApi: ({ profile_states, latest_brand_analysis, latest_non_brand_analysis }) => ({
    myBrandAnalysis: latest_brand_analysis
      ? {
        id: latest_brand_analysis?.id,
        profileId: latest_brand_analysis?.brand_account_id,
        name: latest_brand_analysis?.brand_account_name,
        pictureUrl: latest_brand_analysis?.brand_account_picture_url,
        mention: latest_brand_analysis?.brand_account_mention,

        followersCount: latest_brand_analysis?.followers_count,
        uniqueFollowersCount: latest_brand_analysis?.unique_followers_count,
        overlappedRate: latest_brand_analysis?.overlapped_followers_rate,
        overlappedCount: latest_brand_analysis?.overlapped_followers_count,
        reach: latest_brand_analysis?.potential_additional_reach,
      }
      : null,
    kolsAnalysis: latest_non_brand_analysis
      ? {
        id: latest_non_brand_analysis?.id,
        listId: latest_non_brand_analysis?.list_id,
        followersCount: latest_non_brand_analysis?.followers_count,
        uniqueFollowersCount: latest_non_brand_analysis?.unique_followers_count,
        overlappedRate: latest_non_brand_analysis?.overlapped_followers_rate,
        overlappedCount: latest_non_brand_analysis?.overlapped_followers_count,
        instagramAccount: Object.keys(profile_states || {})?.length,
        rows: Object.entries(profile_states || {})?.reduce( (prev, [key, value]) => [
          ...prev,
          {
            profileId: key,
            accountId: value?.account_id,
            mention: value?.username,
            state: value?.state,
            name: value?.name,
            pictureUrl: value?.picture_url,

            followersCount: latest_non_brand_analysis?.analysis?.[key]?.followers_count,
            uniqueFollowersCount: latest_non_brand_analysis?.analysis?.[key]?.unique_followers_count,
            uniqueFollowersRate: latest_non_brand_analysis?.analysis?.[key]?.unique_followers_rate,
            uniqueWeight: latest_non_brand_analysis?.analysis?.[key]?.unique_weight,
            overlappedRate: latest_non_brand_analysis?.analysis?.[key]?.overlapped_followers_rate,
            overlappedCount: latest_non_brand_analysis?.analysis?.[key]?.overlapped_followers_count,
          }
        ], [])
      }
      : null
  }),
}

export default {
  aggregateData,
  getProfilesList,
  loadLegacyProfiles,
  getAccountCreationRequests,
  audienceOverlapStatus,
  audienceOverlapStatistics,
};
