import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import * as api from './nsmDashboard.api';
import {
  DataFromApi,
  DataParameters,
  Mention,
} from './nsmDashboard.types';


export const saveSetup = createAsyncThunk<
  DataFromApi,
  { mentions: Mention[], isUpdate: boolean },
  { state: RootState }
>(
  'nsmDashboard/saveSetup',
  async ({mentions, isUpdate}) => {
    const response = isUpdate
      ? await api.updateSetup(mentions)
      : await api.saveSetup(mentions)

    if (!response?.error) {
      const intl = getIntl();
      toast(
        intl.formatMessage({id: 'nsm.editSetup.success'}),
        {type: 'success'},
      );
      return Promise.resolve(response);
    } else {
      return Promise.reject(response.error);
    }
  },
);

const cleanLocalStorage = ({key, data}) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(error);
  }
}

export const loadData = createAsyncThunk<
  DataFromApi,
  DataParameters,
  { state: RootState }
>(
  'nsmDashboard/loadData',
  async (filters, { dispatch }) => {
    const params: DataParameters = {
      start_at: filters.start_at,
      end_at: filters.end_at,
      social_network_mentions: filters.social_network_mentions?.length
        ? filters.social_network_mentions
        : [],
      audience_location: filters.audience_location?.length
        ? filters.audience_location?.map((location) => ({
            country: location.country,
            percentage: Number(location.percentage)
          }))
        : [],
      exclude_own_mentions: filters.exclude_own_mentions || false,
    };

    const response = await api.loadDataByPost(params);

    if (response?.error === 'require_refetch') {
      const newMentions = response?.lighthouse?.social_network_mentions;
      const cleanedMentions = filters.social_network_mentions?.filter(({ mention, network }) => {
        return newMentions
          ?.findIndex(({ mention: newMention, network: newNetwork }) => mention === newMention && network === newNetwork) > -1
      });

      const cleanParams = {
        ...params,
        social_network_mentions: cleanedMentions,
      };

      cleanLocalStorage({
        key: `key_metrics_dashboard`,
        data: cleanParams,
      });

      setTimeout(() => {
        void dispatch(loadData(cleanParams));
      }, 1000);
    }

    if (!response?.error) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response.error);
    }
  },
);
