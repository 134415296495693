import React, { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

const base = {
  maxWidth: '350px',
  paddingTop: '10px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingBottom: '10px',
  borderRadius: '8px',
  textAlign: 'left',
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'var(--font-family)',
};

const popper = {
  [`&.${tooltipClasses.popper}`]: {
    zIndex: '2147483647',
  },
}

const arrow = {
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: '0',
  },
};

const stylesDefault = {
  ...arrow,
  ...popper,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--color-dominant-on-dominant)',
    color: '#fff',
    boxShadow: '0 8px 16px 0 rgba(0, 48, 87, 0.3)',
    ...base,
  },
};

const stylesLight = {
  ...arrow,
  ...popper,
  [`& .${tooltipClasses.tooltip}`]: {
    ...base,
    backgroundColor: '#edf1f7',
    color: 'var(--color-dominant-on-dominant)',
  },
};

const stylesCustom = {
  ...arrow,
  ...popper,
  [`& .${tooltipClasses.tooltip}`]: {
    ...base,
    backgroundColor: '#fff',
    color: 'var(--color-dominant-on-dominant)',
    border: '1px solid #CCD6E3',
    boxShadow: '0 8px 16px 0 rgba(0, 48, 87, 0.3)',
  },
};

const ToBeStyledTooltip = ({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
);

const Tooltip = ({
  children,
  content,
  position,
  theme,
  delay,
  enterTouchDelay,
  arrow,
  ...rest
}) => {
  const displayContent = useMemo(
    () => (content === null ? '' : content),
    [content],
  );

  const StyledTooltip = useMemo(() => {
    switch (theme) {
      case 'light':
        return styled(ToBeStyledTooltip)(() => (stylesLight));
      case 'custom':
        return styled(ToBeStyledTooltip)(() => (stylesCustom));
      case 'default':
        default:
        return styled(ToBeStyledTooltip)(() => (stylesDefault));
    }
  }, [theme]);

  return (
    <StyledTooltip
      {...rest}
      arrow={typeof arrow === 'boolean' ? arrow : theme !== 'custom'}
      title={displayContent}
      placement={position}
      enterDelay={delay}
      enterTouchDelay={enterTouchDelay}
    >
      {children}
    </StyledTooltip>
  );
};

Tooltip.defaultProps = {
  content: null,
  position: 'top',
  delay: null,
  enterTouchDelay: 100,
  theme: 'default',
};

export default memo(Tooltip);
