/* eslint-disable */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'utils/redux';
import { env, ENV_PROD } from 'config/env';

const isNotProd =
  window.location.href.includes('staging') ||
  window.location.href.includes('localhost') ||
  window.location.href.includes('lab');

const TrackerTeamBlue = () => {
  const sfId = useSelector(({ user }) => user?.profile?.company?.sfId);

  useEffect(() => {
    if (!window.trk_ext) {
      window.trk_ext = {};
    }
    window.trk_ext.trkC_bill = sfId;
  }, [sfId]);

  if (!window.trk_ext || isNotProd) return null;

  return (
    <Helmet>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.TRK = {
              domain: 'kolsquare.com',
              teamblue: 1,
              ready: 0
            };
            window.trk_ext = window.trk_ext || {};
            window._dadaq = window._dadaq || [];
            window.trk_ext.trkC_bill = "${sfId}";
          `,
        }}
      ></script>
    </Helmet>
  );
};

export default TrackerTeamBlue;
