import { useEffect, useContext, useRef, useState, useCallback } from 'react';
import { Subscription } from '@rails/actioncable';

import { ActionCableContext } from 'config/actioncable';
import useDeepComparaison from '../useDeepComparaison';

const useActioncableSubscription = ({
  channel,
  params = undefined,
  onDataReceived,
  onConnected = undefined,
  skip = false,
}: {
  channel: string;
  params?: Record<string, string | number>;
  onDataReceived: any;
  onConnected?: () => void;
  skip?: boolean;
}) => {
  const cable = useContext(ActionCableContext);

  const cachedParams = useDeepComparaison(params || {}) as unknown as Record<
    string,
    string | number
  >;

  const refChannel = useRef<Subscription | null>(null);

  const [connected, setConnected] = useState(false);

  const unsubscribe = useCallback(() => {
    if (refChannel) {
      refChannel?.current?.unsubscribe();
    }
  }, [refChannel]);

  useEffect(() => {
    return () => {
      setConnected(false);
      if (refChannel) unsubscribe();
    };
    // eslint-disable-next-line
  }, [cachedParams]);

  useEffect(() => {
    if (cable && channel && !skip && !connected) {
      refChannel.current = cable.subscriptions.create(
        { channel, ...cachedParams },
        {
          received: onDataReceived,
          initialized: () =>
            console.info(`[actionCable -> ${channel} initialized]`),
          connected: () => {
            console.info(`[actionCable -> ${channel} connected]`);
            setConnected(true);
            if (onConnected) onConnected();
          },
          disconnected: () => {
            console.info(`[actionCable -> ${channel} disconnected]`);
            // Client seems to reconnect automatically, so commenting it for now
            // setConnected(false);
          },
          rejected: () => console.info(`[actionCable -> ${channel} rejected]`),
        },
      );
    }
  }, [
    refChannel,
    cable,
    connected,
    onDataReceived,
    onConnected,
    cachedParams,
    skip,
    channel,
  ]);

  return {
    connected,
    channel: refChannel.current,
    unsubscribe,
  };
};

export default useActioncableSubscription;
