import { createAsyncThunk } from '@reduxjs/toolkit';
import { FullStory } from '@fullstory/browser';
import history from 'config/history';

import { batch } from 'actions/profiles';
import { showModal } from 'actions/ui';
import { RootState } from 'config/store';
import { mapNewProfileToOldOne } from 'mappers/searchEngine';
import { PaginatedResponse } from 'types';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import JsonFormatter from 'utils/JsonFormatter';
import toast from 'utils/toast';
import { MODAL_ID } from 'components/search/SearchEngine/modules/SavedSearchesModal';
import { getFiltersByNetwork } from 'components/search/SearchEngine/hooks/useFiltersByNetwork';

import * as api from './searchEngine.api';
import type { LaunchSearchResponse, SavedSearch, SearchParams } from './searchEngine.types';
import { mapReduxParamsToApiParams } from './searchEngine.utils';
import { setSort, setViewMode } from '.';

/**
 * Navigate to search page with current parameters. Then URL params are consumed by SearchEngine component.
 */
export const initSearch = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('search/searchEngine/initSearch', async (param, { getState, dispatch }) => {
  const { terms, sort, search_scope, instagram_filters, researchable_element } = getState().search.searchEngine;
  // Default sort to 'community_count' if no 'terms'
  if (
    terms?.trim()?.length === 0 &&
    (sort === 'latest_post' || sort === 'relevance')
  ) {
    dispatch(setSort({ sort: 'community_count' }));
  }

  // Reset view mode to 'profile' if no 'terms' and no instagram 'content_location'
  if (
    researchable_element === 'content' &&
    (search_scope !== 'content' ||
      (terms.length === 0 && instagram_filters?.content_location === undefined))
  ) {
    dispatch(setViewMode({ researchable_element: 'profile' }));
  }

  const reduxParams = getState().search.searchEngine;

  // For fullstory event, get number of filters by network
  const filtersByNetwork = getFiltersByNetwork(reduxParams);

  FullStory('trackEvent', {
    name: 'SEA-nb-filters',
    properties: {
      profile: Object.keys(filtersByNetwork.profile)?.length,
      instagram: Object.keys(filtersByNetwork.instagram)?.length,
      tiktok: Object.keys(filtersByNetwork.tiktok)?.length,
      youtube: Object.keys(filtersByNetwork.youtube)?.length,
      facebook: Object.keys(filtersByNetwork.facebook)?.length,
      twitter: Object.keys(filtersByNetwork.twitter)?.length,
      'with-ig-content-location': instagram_filters?.content_location !== undefined
    },
  });

  // Find out which value was selected when the search was launched.
  FullStory('trackEvent', {
    name: 'SEA-search-type',
    properties: { type: reduxParams.search_scope },
  });


  try {
    // INFO: We don't need to send the page param to the URL, it's only used for pagination.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, ...uncleanedParams}  = mapReduxParamsToApiParams(reduxParams, false);
    const compressedParams = JsonFormatter.compress(
      JSON.stringify(uncleanedParams),
    );
    const currentParams = new URLSearchParams(history.location.search);
    currentParams.set('q', compressedParams);
    history.push({ pathname: '/', search: `?${currentParams?.toString()}` });
    return Promise.resolve();
  } catch (e) {
    console.error('Unparseanble JSON params from redux: ', e);
  }

  return Promise.reject(new Error("Failed search"));
});


export const launchSearchFromReduxParams = createAsyncThunk<
  LaunchSearchResponse,
  void,
  { state: RootState }
>(
  'search/searchEngine/launchSearchFromReduxParams',
  async (_params, { getState }) => {
    const reduxParams = getState().search.searchEngine;
    const params  = mapReduxParamsToApiParams(reduxParams, true);
    const response = await api.launchSearch(params);

    if (!response?.error) {
      if (
        response.public_profiles?.length === 0 &&
        params.researchable_element === 'profile'
      ) {
        FullStory('trackEvent', {
          name: 'SEA-kols-no-results',
          properties: {},
        });
      }
      if (
        response.contents?.length === 0 &&
        params.researchable_element === 'content'
      ) {
        FullStory('trackEvent', {
          name: 'SEA-contents-no-results',
          properties: {},
        });
      }

      return response;
    }
  },
);

export const launchSearchStandalone = createAsyncThunk<
  LaunchSearchResponse,
  SearchParams,
  { state: RootState }
>(
  'search/searchEngine/launchSearchStandalone',
  async (params, { dispatch }) => {
    const response = await api.launchSearch(params);

    if (!response?.error) {
      if (response.public_profiles?.length > 0) {
        dispatch(
          batch(
            response.public_profiles?.map((profile) =>
              mapNewProfileToOldOne(profile),
            ),
          ),
        );
      }
      return response as LaunchSearchResponse;
    }

    return {} as LaunchSearchResponse;
  },
);

export const exportCurrentSearch = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('search/searchEngine/exportSearch', async (param, { getState }) => {
  const {
    search: {
      searchEngine: { id: searchId },
    },
  } = getState();

  if (searchId) {
    const intl = getIntl();
    toast(
      intl.formatMessage({
        id: 'engine.actions.export.toast.started',
      }),
      {
        type: 'info',
      },
    );

    const response = await api.exportCurrentSearch({ id: searchId });

    // Get the filename
    let filename = 'search-results.csv';
    const header = response.headers.get('Content-Disposition');
    if (header) {
      const parts = header.split(';');
      filename = parts[1].split('=')[1].replace(/"/g, '');
    }

    // Simulate a link click
    const blob = await response.blob(); // create a new Blob object.
    const url = window.URL.createObjectURL(blob); // create a new object url
    const a = document.createElement('a'); // create a new anchor element
    a.href = url; // set its href to the object URL
    a.download = filename; // set its download attribute to the desired filename.
    a.click(); // programmatically clicking the anchor element to trigger the file download.
    toast(
      intl.formatMessage({
        id: 'engine.actions.export.toast.finished',
      }),
      {
        type: 'success',
      },
    );
  }
});

export const fetchSavedSearches = createAsyncThunk<
  PaginatedResponse<{ researches: SavedSearch[] }>,
  void,
  { state: RootState }
  // @ts-ignore
>('search/searchEngine/fetchSavedSearches', async () => {
  const response = await api.fetchSavedSearches();

  if (response && response.error) {
    return Promise.reject(response);
  }

  return Promise.resolve({ ...response });
});

export const saveSearch = createAsyncThunk<
  void,
  { params: SavedSearch; silent?: boolean; isUpdate?: boolean },
  { state: RootState }
>('search/searchEngine/saveSearch', async ({ params, silent, isUpdate }) => {
  const mappedParams = mapReduxParamsToApiParams(params);

  const saveSearchParams = {
    ...mappedParams,
    saved: true,
    search: null,
    id: params.id,
    name: params.name,
  };
  // INFO: There is no ID if the search has not been launched yet, so launch it with the `saved: true` param.
  const response = await (mappedParams.id
    ? api.saveSearch(saveSearchParams)
    : api.launchSearch(saveSearchParams));

  if (response && !response.error) {
    if (!silent) {
      const intl = getIntl();
      toast(
        intl.formatMessage({
          id: isUpdate
            ? 'savedSearches.toast.updated'
            : 'savedSearches.toast.saved',
        }),
        {
          type: 'success',
          title: intl.formatMessage({ id: 'global.success' }),
        },
      );
    }
    FullStory('trackEvent', {
      name: 'SEA-CTA-save',
      properties: {},
    });
    return Promise.resolve();
  }
  return Promise.reject();
});

export const deleteSearch = createAsyncThunk<
  void,
  SavedSearch,
  { state: RootState }
>('search/searchEngine/deleteSearch', async (params, { dispatch }) => {

  const response = await api.deleteSearch(params.id);

  if (response && !response.error) {
    const intl = getIntl();
    toast(intl.formatMessage({ id: 'savedSearches.toast.deleted' }), {
      type: 'success',
      title: intl.formatMessage({ id: 'global.success' }),
    });

    void dispatch(fetchSavedSearches());
    return Promise.resolve();
  }

  return Promise.reject();
});

export const saveCurrentFilters = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('search/searchEngine/saveCurrentFilters', (_params, { dispatch, getState }) => {
  const params = getState().search.searchEngine;
  void dispatch(
    showModal({
      id: MODAL_ID,
      data: {
        type: 'save',
        search: params,
      },
    }),
  );
});
