import { useMemo } from 'react';
import { useSelector } from 'utils/redux';

/**
 * Custom hook to create a translator using Intl.DisplayNames
 * @param {string} type - Type of names ('region', 'language')
 * @returns {function} - Translator function to get the localized name
 */
const useIntlDisplayNames = (type) => {
  const localeIso2 = useSelector(({ env }) => env?.locale?.slice(0, 2));

  const displayNames = useMemo(
    () => {
      if (!['region', 'language'].includes(type)) return null;

      const locale = localeIso2 || 'en';

      try {
        return new Intl.DisplayNames([locale], { type });
      } catch (error) {
        console.error('useIntlDisplayNames:', error);
        return null;
      }
    },
    [localeIso2, type]
  );

  return (code) => displayNames?.of(code) || code;
};

export default useIntlDisplayNames;

