import { AnyAction, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import reducers from '../reducers/index';

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const store = configureStore({
  reducer: reducers,
  devTools: {
    maxAge: 50,
    serialize: {
      replacer: (key, value) => (value instanceof Map ? [...value] : value)
    }
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true
    })
});

if (module.hot?.accept) {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  module.hot.accept('../reducers', async () => {
    const newReducers = (await import('../reducers/index')).default;
    store.replaceReducer(newReducers);
  });
}

export const getStore = (): RootState => store.getState();
export const getDispatcher = (): AppDispatch => store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export default store;
