/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import _uniq from 'lodash.uniq';

import * as PROFILES from 'constants/profiles';
import autoUpdateKeys from 'utils/autoUpdateKeys';
import { Profile } from 'types/profiles';

const detailedStructure = {
  id: null,
  core: {},
  audience: {},
  about: {},
  topHashtags: [],
  content: {},
  note: {},
  tags: [],
  history: {},
  snas: {},
  statsSharing: {
    status: ''
  },
  snasWithBio: [],
  top: {
    countries: [],
    ages: [],
    interests: [],
    genders: [],
  },
  campaigns: {
    loaded: false,
    ids: [],
  },
  contactableEmail: '',
  lastContactId: null,
  contact: [],
  details: {
    affilaeId: '',
    pseudo: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    agentName: '',
    agentEmail: '',
    agentPhoneNumber: '',
    projectsInvestedBudget: 0,
    investedBudget: 0,
  },
  lists: [],
  fields: {},
  brand_collaborations: {
    brands: [],
    date_labels: [],
    max_content_per_period: 0,
    page: 1,
    per_page: 5,
    total: 0,
  }
};

const snaStructure = {
  username: '',
  network: '',
  url: '',
  isPrivate: false,
  stats: {},
  evolution: {},
  performance: {},
};


const initialState: Record<number, Profile> = {};

const reducers = {
  [PROFILES.UPDATE]: (state, { payload: { id, ...datas }}) => {
    // Profile doesn't exists -> create
    if (!state[id]) return update(state, {
      $merge: {
        [id]: {
          ...detailedStructure,
          ...datas,
          id,
        }
      }
    });

    // Profile already exists -> update
    return update(state, {
      [id]: {
        $apply: autoUpdateKeys(datas)
      }
    });
  },

  [PROFILES.BATCH]: (state, { payload: profiles }) => profiles.reduce((p, { id, ...datas }) => {
    // Profile doesn't exists
    if (!p[id]) {
      return update(p, {
        $merge: {
          [id]: {
            ...detailedStructure,
            ...datas,
            id,
          }
        }
      })
    }
    // Update all profile keys
    return update(p, {
      [id]: {
        $apply: autoUpdateKeys(datas)
      }
    })
  }, state),

  [PROFILES.UPDATE_SNA]: (state, {payload:{id, snaId, ...datas}}) => update(state, {
    [id]: {
      snas: {
        [snaId]: {
          $apply: autoUpdateKeys(datas)
        }
      }
    }
  }),
  [PROFILES.UPDATE_SNAS]: (state, {payload:{id, snas}}) => snas.reduce((p, {id:snaId, ...datas}) => {
    // SNA doesn't exists yet
    if (!p[id].snas[snaId]) return update(p, {
      [id]: {
        snas: {
          $merge: {
            [snaId]: {
              ...snaStructure,
              ...datas,
              id: snaId,
            }
          }
        }
      }
    });

    // Update all SNA keys with merge if object, or set
    return update(p, {
      [id]: {
        snas: {
          [snaId]: {
            $apply: autoUpdateKeys(datas)
          }
        }
      }
    })
  }, state),
  [PROFILES.LOAD_CAMPAIGNS]: (state, {payload: { id, campaignsIds }}) => update(state, {
    [id]: {
      campaigns: {
        $merge: {
          loaded: true,
          ids: campaignsIds,
        }
      }
    }
  }),

  [PROFILES.LOAD_LISTS]: (state, { payload: { kolIds, listIds }}) => {
    if (kolIds) {
      kolIds?.forEach(id => {
        state[id] = {
          ...detailedStructure,
          ...state?.[id],
          id: Number(id),
          lists: state?.[id]?.lists
            ? [...state[id].lists, ...listIds]
            : listIds
        }
      })
    }
  },

  [PROFILES.LOAD_LISTS_TO_PROFILES]: (state, { payload }) => {
    payload.forEach(({ kolIds, listIds }) => {
      kolIds?.forEach(id => {
        state[id] = {
          ...detailedStructure,
          ...state?.[id],
          id: Number(id),
          lists: state?.[id]?.lists
            ? [...state[id].lists, ...listIds]
            : listIds
        }
      })
    })
  },

  [PROFILES.ADD_CAMPAIGN]: (state, { payload: { profileIds, campaignId }}) => {
    profileIds.forEach(id => {
      if (state?.[id]?.campaigns?.ids) {
        state[id].campaigns.ids = _uniq([
          ...(state?.[id]?.campaigns?.ids || []),
          campaignId
        ])
      }
      return true;
    });
  },

  [PROFILES.DELETE_CAMPAIGN]: (state, {payload: {profileIds, campaignId}}) => {
    return profileIds.reduce((p, id) => {
      return update(p, {
        [id]: {
          campaigns: {
            ids: {
              $set: state[id].campaigns.ids.filter( id => id !== campaignId)
            },
          }
        }
      });
    }, state);
  },

  [PROFILES.UPDATE_LIST]: (state, { payload: { profileIds, listIds, action = 'add' }}) => {
    profileIds.forEach(id => {
      if (state[id]?.lists) {
        if (action === 'add') {
          state[id].lists = _uniq([
            ...(state?.[id]?.lists || []),
            ...listIds
          ])
        }
        if (action === 'remove') {
          state[id].lists = state[id].lists.filter(id => !listIds.includes(id))
        }
      }
      return true;
    });
  },

  [PROFILES.UPDATE_EMAILS]: (state, { payload: { profileId, emailId, data }}) => {
    if (state?.[profileId]) {
      if (emailId) {
        const findKey = state?.[profileId].contact?.findIndex(item => Number(item.id) === Number(emailId));
        state[profileId].contact[findKey] = data;
      }
      if (!emailId) state[profileId].contact.push(data);
    }
  },
};

export default createReducer(initialState, reducers)
