import { useEffect, useState, useCallback } from 'react';
import getLocalStorageItem from 'utils/getLocalStorageItem';

const useLocalStorage = (key, initialValue) => {
  const isStorageAvailable = () => {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const [storedValue, setStoredValue] = useState(() => {
    return isStorageAvailable() ? getLocalStorageItem(key, initialValue) : initialValue;
  });

  const setValue = useCallback(
    (value) => {
      try {
        if (!isStorageAvailable()) return null;

        window.localStorage.setItem(key, JSON.stringify(value));

        const event = new CustomEvent('onLocalStorageChanged', {
          detail: { key, newValue: value },
        });

        document.dispatchEvent(event);
      } catch (error) {
        console.error('Erreur lors de l’écriture dans localStorage:', error);
      }
    },
    [key]
  );

  useEffect(() => {
    if (!isStorageAvailable()) return null;

    const handleStorageChange = (event) => {
      if (event.detail?.key === key) {
        setStoredValue(event.detail.newValue);
      }
    };

    const handleStorageEvent = (event) => {
      if (event.key === key) {
        setStoredValue(event.newValue ? JSON.parse(event.newValue) : initialValue);
      }
    };

    document.addEventListener('onLocalStorageChanged', handleStorageChange);
    window.addEventListener('storage', handleStorageEvent);

    return () => {
      document.removeEventListener('onLocalStorageChanged', handleStorageChange);
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [key, initialValue]);

  return [storedValue, setValue];
};

export default useLocalStorage;

