import * as mapper from 'mappers/profiles';
import { aggregateData } from 'mappers/myInfluencers';
import { getSnaUrlNetworkType, formatSnaUrl } from 'validators/sna';
import aggregateDebugData from 'utils/data/aggregate.json'

import { get, remove, post, put } from './index';

export const suggestInfluencer = async query =>
  post({
    endpoint: `influence/profiles`,
    params: mapper.suggestInfluencer.toApi(query),
    default204: {},
    debug: true,
    catchErrors: [422],
  });

export const loadProfileCore = (id, params) =>
  get({
    endpoint: `influence/profiles/${id}`,
    params,
    catchErrors: [404],
  });

export const loadProfileAbout = ({ id, ...query }) => get({
  endpoint: `influence/profiles/${id}/about`,
  params: mapper.loadProfileAbout.toApi(query),
  catchErrors: [404],
});

export const loadProfileSnasEmv = ({id}) => get({
  endpoint: `influence/profiles/${id}/emv`,
  catchErrors: [404],
});

// accounts - accounts/
export const loadProfileStats = ({id}) => get({
  endpoint: `influence/profiles/${id}/accounts`,
  catchErrors: [404],
});

export const suggestSnaForKOL = async ({ id, url }) => {
  const ignoreRegex = url.includes('youtube.com') || url.includes('facebook.com');
  return post({
    endpoint: `influence/profiles/${id}/accounts`,
    params: {
      url: formatSnaUrl(url, ignoreRegex),
      type: getSnaUrlNetworkType(url),
    },
    catchErrors: [422, 404],
    debug: true,
  });
};

export const mergeProfiles = async ({
  profile_ids,
  profile_picture_id,
  pseudo,
}) =>
  post({
    endpoint: `influence/v2/profiles/merge`,
    params: {
      profile_ids,
      profile: {
        profile_picture_id,
        pseudo,
      },
    },
  });

export const loadProfileTopHashtags = id => get({
  endpoint: `influence/profiles/${id}/top_hashtags`,
  catchErrors: [404],
});

export const loadProfilePosts = ({id, debug, ...query}) => get({
  endpoint: `influence/profiles/${id}/posts`,
  params: mapper.loadProfilePosts.toApi(query),
  catchErrors: [404],
});

export const loadProfileNote = id => get({
  endpoint: `influence/profiles/${id}/note`,
  catchErrors: [404],
});

export const loadProfileCampaignsIds = id => get({
  endpoint: `influence/profiles/${id}/projects`,
  catchErrors: [404],
  debug: [],
});

export const loadProfileCampaignsHistory = ({ id, ...query }) => get({
  endpoint: `influence/profiles/${id}/campaigns`,
  params: query,
  catchErrors: [404],
});

export const getTags = id => get({
  endpoint: `influence/profiles/${id}/taggings`,
  debug: [{
    id: 1,
    value: "Michel"
  }],
  catchErrors: [404]
});

export const removeTag = ({ id, tagId }) => remove({
  endpoint: `influence/profiles/${id}/taggings/${tagId}`,
  catchErrors: [404],
  debug: true
});

export const updateProfileTags = ({ id, tags }) => post({
  endpoint: `influence/profiles/${id}/taggings`,
  params: mapper.updateProfileTags.toApi({ tags }),
  debug: true,
});


export const updateProfilesTags = ({ ids, tags }) => post({
  endpoint: `influence/profiles/taggings/batch`,
  params: mapper.updateProfilesTags.toApi({ ids, tags }),
  debug: true,
});

export const getTopMentions = ({ id, filters }) => get({
  endpoint: `influence/profiles/${id}/${filters?.mentioned ? 'my_mentions':'who_mentioned_me'}`,
  params: mapper.getTopMentions.toApi(filters),
  catchErrors: [404]
});

export const reportProfile = ({id, key, value}) => post({
  endpoint: `influence/profiles/${id}/reports`,
  params: mapper.reportProfile.toApi({key, value}),
  debug: {
    timer: 1000
  },
});

export const reportThemes = ({id, wrongThemes, proposedThemes}) => post({
  endpoint: `influence/profiles/${id}/reports`,
  params: mapper.reportThemes.toApi({wrongThemes, proposedThemes}),
  debug: {
    timer: 1000
  },
});

export const saveContactableEmail = ({profileId, email }) => put({
  endpoint: `influence/profiles/${profileId}/custom_email`,
  params: { email },
  debug: {
    timer: 1000,
  },
});



/** *******
 *
 * MANAGE EMAILS CONTACT
 */

export const loadContactableEmails = profileId => get({
  endpoint: `influence/profiles/${profileId}/custom_emails`,
  catchErrors: [404],
  debug: true
});

export const loadContactableEmailsBatch = ({ profileIds }) =>
  post({
    endpoint: `influence/profiles/fetch_emails`,
    params: { profile_ids: profileIds },
    catchErrors: [],
    debug: true,
  });

export const createContactableEmails = ({ profileId, email, category }) => post({
  endpoint: `influence/profiles/${profileId}/custom_emails`,
  params: { email, category },
  debug: true,
});

export const editContactableEmails = ({ profileId, emailId, email, category }) => put({
  endpoint: `influence/profiles/${profileId}/custom_emails/${emailId}`,
  params: Object.assign({ email },
    category ? { category } : null,
  ),
  debug: true,
});

export const deleteContactableEmails = ({ profileId, emailId }) => remove({
  endpoint: `influence/profiles/${profileId}/custom_emails/${emailId}`,
  debug: true,
});

export const selectContactableEmails = ({ profileId, emailId }) => put({
  endpoint: `influence/profiles/${profileId}/custom_emails/${emailId}/update_primary`,
  params: { is_primary: true },
  debug: true
});


/** *******
 *
 * MANAGE PROFILE DETAILS
 */

export const getProfileDetails = profileId => get({
  endpoint: `influence/profiles/${profileId}/detail`,
  debug: true,
  catchErrors: [404]
});

export const updateProfileDetails = (profileId, details) =>
  put({
    endpoint: `influence/profiles/${profileId}/detail`,
    params: mapper.getProfileDetails.toApi(details),
    debug: mapper.getProfileDetails.toApi(details).detail,
    catchErrors: [404]
  });


/* ********
 *
 * LOOK A LIKE
 * stored in views object
 */

export const getLookalike = data => get({
  endpoint: `influence/similar_profiles`,
  params: mapper.getLookalike.toApi(data),
});


/* ********
 *
 * CUSTOM FIELDS
 *
 */

export const getProfileCustomFields = profileId => get({
  endpoint: `influence/profiles/${profileId}/custom_fields`,
  catchErrors: [404],
});

export const addFieldsToProfile = ({ fields, profileId }) => post({
  endpoint: `influence/profiles/${profileId}/custom_fields/batch`,
  params: {
    custom_fields: fields,
  },
  catchErrors: [422],
  debug: true,
});

export const removeFieldsToProfile = ({ fields, profileId }) => remove({
  endpoint: `influence/profiles/${profileId}/custom_fields/batch`,
  params: {
    custom_fields: fields,
  },
  catchErrors: [422],
  debug: true,
});


/* ********
 *
 * LIST
 *
 */

export const getProfileLists = profileId => get({
  endpoint: `influence/profiles/${profileId}/lists`,
  debug: [5000, 753],
  catchErrors: [404]
});

export const addProfilesToLists = ({ listIds, profileIds }) => post({
  endpoint: `influence/custom_lists/profiles/batch`,
  params: {
    list_ids: listIds.map(id => Number(id)),
    profile_ids: profileIds.map(id => Number(id)),
  },
  catchErrors: [422],
  debug: true,
});

export const removeProfilesToLists = ({ listIds, profileIds }) => remove({
  endpoint: `influence/custom_lists/profiles/batch`,
  params: {
    list_ids: listIds.map(id => Number(id)),
    profile_ids: profileIds.map(id => Number(id)),
  },
  catchErrors: [422],
  debug: true,
});

export const loadProfilelistIds = id => get({
  endpoint: `influence/profiles/${id}/lists`,
  debug: [],
});

/**
 *
 * AGGREGATE
 */

export const getAggregateData = ({ listId, profileIds, contactIds, tagOperator, tagsIds }) => get({
  endpoint: `influence/profiles/aggregate`,
  params: aggregateData.toApi({ listId, profileIds, contactIds, tagOperator, tagsIds }),
  debug: aggregateDebugData,
});
