import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import cn from 'classnames';

import Icon from '../Icon';
import Loader from '../Loader';
import Footer from './Footer2';
import ModalFooter from './ModalFooter2';

import styles from './Modal2.module.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal2 = forwardRef(({
  children,
  on,
  title,
  onClick,
  action,
  className,
  fullScreen,
  loading,
  disableBackdropClick,
  fullHeight,
  disableEscapeKeyDown,
  modalFooter,
  modalFooterNoDividerMargin,
  footerHint,
  headerActions,
  width,
  subTitle,
  classNameSubtitle,
  noScroll,
  size,
  isAlert,
  dataId,
  ...rest
}, ref) => {
  const cnSubtitle = cn(styles.subTitle, classNameSubtitle);

  const handleClick = useCallback(
    (e, reason) => {
      if ((isAlert || disableBackdropClick) && reason === 'backdropClick')
        return null;
      onClick(e, reason); // reason: "escapeKeyDown", "backdropClick"
    },
    [onClick, isAlert, disableBackdropClick]
  );

  const renderHeader = useMemo(
    () => {
      if (!title && !subTitle && !headerActions)
        return null;

      return (
        <div className={styles.header}>
          <div className={styles.titleWithActions}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {headerActions}
          </div>
          {subTitle && <h3 className={cnSubtitle}>{subTitle}</h3>}
        </div>
      )
    },
    [title, headerActions, subTitle, cnSubtitle]
  );

  const renderFooter = useMemo(
    () => action && (
      <Footer action={action} hint={footerHint} />
    ),
    [action, footerHint]
  );

  const renderModalFooter = useMemo(
    () => modalFooter && (
      <ModalFooter noDividerMargin={modalFooterNoDividerMargin} >
        {modalFooter}
      </ModalFooter>
    ),
    [modalFooter, modalFooterNoDividerMargin]
  );

  const cnDialog = cn(styles.dialog, styles[size]);

  const cnModal = cn(styles.modal, styles[width], className, {
    [styles.fullScreen]: fullScreen,
    [styles.fullHeight]: fullHeight,
    [styles.noScroll]: noScroll,
    [styles.noTitle]: !title,
    [styles.noAction]: !action
  });

  return (
    <Dialog
      ref={ref}
      classes={{
        root: cnDialog,
        paper: styles.paper,
      }}
      onClose={handleClick}
      open={on}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      disableEscapeKeyDown={!isAlert ? disableEscapeKeyDown : true}
      {...rest}
    >
      <div className={cnModal}>
        {!isAlert && (
          <Icon
            label="times"
            theme="regular"
            size="huge"
            className={styles.closeIcon}
            onClick={handleClick}
            fill="var(--color-dominant-on-dominant)"
            isButton
            dataId={dataId ? `${dataId}-close` : null}
          />
        )}
        {renderHeader}
        <div className={styles.body}>
          <div className={styles.content}>
            {loading && <Loader full background="rgba(255, 255, 255, .8)" />}
            {children}
          </div>
        </div>
        {renderFooter}
        {renderModalFooter}
      </div>
    </Dialog>
  );
});

Modal2.displayName = 'Modal2';

Modal2.propTypes = {
  action: PropTypes.shape({
    primary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.element,
      dataId: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool
    }),
    secondary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.element,
      dataId: PropTypes.string,
      disabled: PropTypes.bool
    })
  }),
  on: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fullScreen: PropTypes.bool,
  fullHeight: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  dataId: PropTypes.string,
  modalFooter: PropTypes.element,
  modalFooterNoDividerMargin: PropTypes.bool,
  footerHint: PropTypes.element,
  headerActions: PropTypes.element,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  noScroll: PropTypes.bool,
  isAlert: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large', 'x-large', 'big']),
  width: PropTypes.oneOf(['default', 'auto'])
};

Modal2.defaultProps = {
  action: undefined,
  title: null,
  subTitle: null,
  fullScreen: false,
  fullHeight: false,
  loading: false,
  className: null,
  classNameSubtitle: null,
  modalFooter: null,
  modalFooterNoDividerMargin: false,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  noScroll: false,
  size: 'default',
  isAlert: false,
  width: 'default',
  onClick: () => {},
  footerHint: null,
  headerActions: null,
  dataId: null,
  children: null
};

// For storybook
export const NotMemoizedModal2 = Modal2;

export default memo(Modal2);
