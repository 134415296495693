import type { SavedSearch, SearchParams } from './searchEngine.types';

const decodeSearchUrl = (url: string) => {
  // exclude compose from messaging to avoid issues
  if (url && url.trim() !== '' && !url.includes('compose')) {
    const urlDecoded = decodeURIComponent(url);
    if (!urlDecoded.includes('q=')) return null;

    try {
      return JSON.parse(urlDecoded.replace(/\??q=/, ''));
    } catch (error) {
      // Invalid JSON syntax OR An error occurred while parsing JSON
      console.error(error);
      return null;
    }
  }
  return null;
};

export const mapOldSearchParams = (search): Omit<SavedSearch, 'id'> => {
  const { name, query } = search;
  const params = decodeSearchUrl(query);

  const oldInstagramFilters = params.filters.socialNetworks?.instagram;
  const oldTiktokFilters = params.filters.socialNetworks?.tiktok;
  const oldYoutubeFilters = params.filters.socialNetworks?.youtube;
  const oldTwitterFilters = params.filters.socialNetworks?.twitter;
  const oldFacebookFilters = params.filters.socialNetworks?.facebook;

  return {
    // Saved search name
    required_networks: [],
    name: name || '',
    // Params
    terms: params.filters.search?.join(' ') || '',
    search_scope:
      // No equivalent for "url"
      { post: 'content', people: 'account', description: 'bio', url: '' }[
        params.type
      ] || 'all',
    researchable_element: 'profile',
    sort: params.sortBy || 'relevance',
    network_filters_operator: 'or',
    basic_filters: [
      // ...['instaconnected'] // No equivalent
      ...(params.filters.arpp_certified ? ['arpp_certified'] : []),
      ...(params.filters.contactable ? ['with_an_email'] : []),
      ...(params.filters.enlisted ? ['influence_for_good'] : []),
      ...(params.filters.status ? ['account_certified'] : []),
      ...(params.filters.seedingKol ? ['seeding'] : []),
    ],
    profile_genders: params.filters.genders || [],
    excluded_project_ids: [], // No equivalent
    excluded_list_ids: [], // No equivalent
    excluded_tag_ids: params.filters.excludeTag?.map(Number) || [],
    profile_type: params.filters.profile_type || 'all',
    exclude_favorites: params.filters.excludeFavorite || false,
    profile_filters: Object.assign(
      {},
      params.filters.languages
        ? {
            languages: {
              type: 'multiple' as const,
              key: 'languages',
              filters: params.filters.languages.map((lang) => ({
                value: lang,
              })),
            },
          }
        : {},
      params.filters.activities
        ? {
            activity_labels: {
              type: 'multiple' as const,
              key: 'activity_labels',
              filters: params.filters.activities.map((activity) => ({
                value: activity,
              })),
            },
          }
        : {},
      params.filters.influencerThemes
        ? {
            influence_themes: {
              type: 'multiple' as const,
              key: 'influence_themes',
              filters: params.filters.influencerThemes.map((theme) => ({
                value: theme,
              })),
            },
          }
        : {},
      params.filters.localisations
        ? {
            profile_location: {
              type: 'location' as const,
              key: 'profile_location',
              filters: params.filters.localisations.map(
                ({ g_place_id, g_city, g_country_code }) => ({
                  value: {
                    id: g_place_id,
                    city: g_city || null,
                    country: g_country_code,
                  },
                }),
              ),
            },
          }
        : {},
    ),
    instagram_filters: Object.assign(
      {},
      oldInstagramFilters?.communitySize
        ? {
            community_size: {
              type: 'range' as const,
              key: 'community_size',
              min: oldInstagramFilters.communitySize.min
                ? Number(oldInstagramFilters?.communitySize.min)
                : undefined,
              max: oldInstagramFilters.communitySize.max
                ? Number(oldInstagramFilters?.communitySize.max)
                : undefined,
            },
          }
        : {},
      params.filters.credibility
        ? {
            audience_credibility_score: {
              type: 'range' as const,
              key: 'audience_credibility_score',
              min: params.filters.credibility
                ? Number(params.filters.credibility)
                : undefined,
            },
          }
        : {},
      oldInstagramFilters?.reelsLikes
        ? {
            reels_likes_count: {
              type: 'range' as const,
              key: 'reels_likes_count',
              min: oldInstagramFilters.reelsLikes.min
                ? Number(oldInstagramFilters?.reelsLikes.min)
                : undefined,
              max: oldInstagramFilters.reelsLikes.max
                ? Number(oldInstagramFilters?.reelsLikes.max)
                : undefined,
            },
          }
        : {},
      oldInstagramFilters?.reelsPlays
        ? {
            reels_impressions: {
              type: 'range' as const,
              key: 'reels_impressions',
              min: oldInstagramFilters.reelsPlays.min
                ? Number(oldInstagramFilters?.reelsPlays.min)
                : undefined,
              max: oldInstagramFilters.reelsPlays.max
                ? Number(oldInstagramFilters?.reelsPlays.max)
                : undefined,
            },
          }
        : {},
      oldInstagramFilters?.reelsEstimatedReach
        ? {
            reels_reach: {
              type: 'range' as const,
              key: 'reels_reach',
              min: oldInstagramFilters.reelsEstimatedReach.min
                ? Number(oldInstagramFilters?.reelsEstimatedReach.min)
                : undefined,
              max: oldInstagramFilters.reelsEstimatedReach.max
                ? Number(oldInstagramFilters?.reelsEstimatedReach.max)
                : undefined,
            },
          }
        : {},
      oldInstagramFilters?.communityEngagementRate
        ? {
            posts_engagement_rate: {
              type: 'range' as const,
              key: 'posts_engagement_rate',
              min: oldInstagramFilters.communityEngagementRate.min
                ? Number(oldInstagramFilters?.communityEngagementRate.min)
                : undefined,
              max: oldInstagramFilters.communityEngagementRate.max
                ? Number(oldInstagramFilters?.communityEngagementRate.max)
                : undefined,
            },
          }
        : {},
      oldInstagramFilters?.communityLanguages
        ? {
            languages: {
              type: 'multiple_range' as const,
              key: 'languages',
              filters: oldInstagramFilters?.communityLanguages.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldInstagramFilters?.communityInterests
        ? {
            influence_themes: {
              type: 'multiple_range' as const,
              key: 'influence_themes',
              filters: oldInstagramFilters?.communityInterests.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldInstagramFilters?.communityAges
        ? {
            community_ages: {
              type: 'multiple_range' as const,
              key: 'community_ages',
              filters: oldInstagramFilters?.communityAges.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldInstagramFilters?.communityGender
        ? {
            community_genders: {
              type: 'multiple_range' as const,
              key: 'community_genders',
              filters: oldInstagramFilters?.communityGender.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldInstagramFilters?.communityCountries
        ? {
            locations: {
              type: 'multiple_range' as const,
              key: 'locations',
              filters: oldInstagramFilters?.communityCountries.map(
                ({ id, countryCode, percent }) => ({
                  value: {
                    id,
                    country: countryCode,
                  },
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
    ),
    tiktok_filters: Object.assign(
      {},
      oldTiktokFilters?.communitySize
        ? {
            community_size: {
              type: 'range' as const,
              key: 'community_size',
              min: oldTiktokFilters.communitySize.min
                ? Number(oldTiktokFilters?.communitySize.min)
                : undefined,
              max: oldTiktokFilters.communitySize.max
                ? Number(oldTiktokFilters?.communitySize.max)
                : undefined,
            },
          }
        : {},
      oldTiktokFilters?.communityEngagementRate
        ? {
            posts_engagement_rate: {
              type: 'range' as const,
              key: 'posts_engagement_rate',
              min: oldTiktokFilters.communityEngagementRate.min
                ? Number(oldTiktokFilters?.communityEngagementRate.min)
                : undefined,
              max: oldTiktokFilters.communityEngagementRate.max
                ? Number(oldTiktokFilters?.communityEngagementRate.max)
                : undefined,
            },
          }
        : {},
      oldTiktokFilters?.communityLanguages
        ? {
            languages: {
              type: 'multiple_range' as const,
              key: 'languages',
              filters: oldTiktokFilters?.communityLanguages.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldTiktokFilters?.communityGender
        ? {
            community_genders: {
              type: 'multiple_range' as const,
              key: 'community_genders',
              filters: oldTiktokFilters?.communityGender.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldTiktokFilters?.communityAges
        ? {
            community_ages: {
              type: 'multiple_range' as const,
              key: 'community_ages',
              filters: oldTiktokFilters?.communityAges.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldTiktokFilters?.communityCountries
        ? {
            locations: {
              type: 'multiple_range' as const,
              key: 'locations',
              filters: oldTiktokFilters?.communityCountries.map(
                ({ id, countryCode, percent }) => ({
                  value: {
                    id,
                    country: countryCode,
                  },
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
    ),
    facebook_filters: Object.assign(
      {},
      oldFacebookFilters?.communitySize
        ? {
            community_size: {
              type: 'range' as const,
              key: 'community_size',
              min: oldFacebookFilters.communitySize.min
                ? Number(oldFacebookFilters?.communitySize.min)
                : undefined,
              max: oldFacebookFilters.communitySize.max
                ? Number(oldFacebookFilters?.communitySize.max)
                : undefined,
            },
          }
        : {},
      oldFacebookFilters?.communityEngagementRate
        ? {
            posts_engagement_rate: {
              type: 'range' as const,
              key: 'posts_engagement_rate',
              min: oldFacebookFilters.communityEngagementRate.min
                ? Number(oldFacebookFilters?.communityEngagementRate.min)
                : undefined,
              max: oldFacebookFilters.communityEngagementRate.max
                ? Number(oldFacebookFilters?.communityEngagementRate.max)
                : undefined,
            },
          }
        : {},
    ),
    twitter_filters: Object.assign(
      {},
      oldTwitterFilters?.communitySize
        ? {
            community_size: {
              type: 'range' as const,
              key: 'community_size',
              min: oldTwitterFilters.communitySize.min
                ? Number(oldTwitterFilters?.communitySize.min)
                : undefined,
              max: oldTwitterFilters.communitySize.max
                ? Number(oldTwitterFilters?.communitySize.max)
                : undefined,
            },
          }
        : {},
      oldTwitterFilters?.communityEngagementRate
        ? {
            posts_engagement_rate: {
              type: 'range' as const,
              key: 'posts_engagement_rate',
              min: oldTwitterFilters.communityEngagementRate.min
                ? Number(oldTwitterFilters?.communityEngagementRate.min)
                : undefined,
              max: oldTwitterFilters.communityEngagementRate.max
                ? Number(oldTwitterFilters?.communityEngagementRate.max)
                : undefined,
            },
          }
        : {},
    ),
    youtube_filters: Object.assign(
      {},
      oldYoutubeFilters?.accountCountry
        ? {
            account_location: {
              type: 'location' as const,
              key: 'account_location',
              filters: oldYoutubeFilters?.communityCountries.map(
                ({ countryCode }) => ({
                  country: countryCode,
                }),
              ),
            },
          }
        : {},
      oldYoutubeFilters?.communitySize
        ? {
            community_size: {
              type: 'range' as const,
              key: 'community_size',
              min: oldYoutubeFilters.communitySize.min
                ? Number(oldYoutubeFilters?.communitySize.min)
                : undefined,
              max: oldYoutubeFilters.communitySize.max
                ? Number(oldYoutubeFilters?.communitySize.max)
                : undefined,
            },
          }
        : {},
      oldYoutubeFilters?.communityEngagementRate
        ? {
            posts_engagement_rate: {
              type: 'range' as const,
              key: 'posts_engagement_rate',
              min: oldYoutubeFilters.communityEngagementRate.min
                ? Number(oldYoutubeFilters?.communityEngagementRate.min)
                : undefined,
              max: oldYoutubeFilters.communityEngagementRate.max
                ? Number(oldYoutubeFilters?.communityEngagementRate.max)
                : undefined,
            },
          }
        : {},
      oldYoutubeFilters?.viewsAverage
        ? {
            videos_avg_views_3m: {
              type: 'range' as const,
              key: 'videos_avg_views_3m',
              min: oldYoutubeFilters.viewsAverage.min
                ? Number(oldYoutubeFilters?.viewsAverage.min)
                : undefined,
              max: oldYoutubeFilters.viewsAverage.max
                ? Number(oldYoutubeFilters?.viewsAverage.max)
                : undefined,
            },
          }
        : {},
      oldYoutubeFilters?.communityLanguages
        ? {
            languages: {
              type: 'multiple_range' as const,
              key: 'languages',
              filters: oldYoutubeFilters?.communityLanguages.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldYoutubeFilters?.communityGender
        ? {
            community_genders: {
              type: 'multiple_range' as const,
              key: 'community_genders',
              filters: oldYoutubeFilters?.communityGender.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldYoutubeFilters?.communityAges
        ? {
            community_ages: {
              type: 'multiple_range' as const,
              key: 'community_ages',
              filters: oldYoutubeFilters?.communityAges.map(
                ({ value, percent }) => ({
                  value,
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
      oldYoutubeFilters?.communityCountries
        ? {
            audience_location: {
              type: 'multiple_range' as const,
              key: 'audience_location',
              filters: oldYoutubeFilters?.communityCountries.map(
                ({ id, countryCode, percent }) => ({
                  value: {
                    id,
                    country: countryCode,
                  },
                  min: Number(percent),
                }),
              ),
            },
          }
        : {},
    ),
    snapchat_filters: Object.assign({}) // TOBE continued new vs old search if has snapchat filters
  };
};

const cleanFilters = <
  T extends
    | SearchParams['profile_filters']
    | SearchParams['instagram_filters']
    | SearchParams['youtube_filters']
    | SearchParams['tiktok_filters']
    | SearchParams['twitter_filters']
    | SearchParams['snapchat_filters']
    | SearchParams['facebook_filters'],
>(
  filters: T,
  options: {
    cleanLocation: boolean;
    isContentSearch: boolean;
  }
) => {
  /**
   * Backend guys asked to remove "country" when we send "city" for filter.type === 'location'
   */
  return Object.entries(filters).reduce((newFilters, [filterKey, filter]) => {
    const newFilter = { ...filter };
    if (options.cleanLocation && filter.type === 'location' && filterKey !== 'content_location') {
      newFilter.filters = filter.filters.map((location) => {
        if (location.city) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { country, city, ...locationWithoutCountryAndCity } = location;
          return locationWithoutCountryAndCity;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, city, ...locationWithoutIdAndCountry } = location;
          return locationWithoutIdAndCountry;
        }
      });
    }

    /**
     * Some keys are differents for contents search: Backend need prefix "content_" for each keys
     */
    const TARGET_CONTENT_SEARCH_ATTRIBUTES = [
      'reels_engagement_rate',
      'reels_likes_count',
      'reels_impressions',
      'reels_reach',
      'stories_impressions',
      'stories_reach',
      'posts_engagement_rate',
      'posts_engagement_3m',
      'posts_comments_3m',
      'posts_likes_3m',
      'posts_impressions',
      'posts_reach',
      'videos_avg_views_3m',
      'spotlights_engagement_rate',
      'spotlights_views',
      'spotlights_reach',
      'saved_stories_engagement_rate',
      'saved_stories_views',
      'saved_stories_reach',
    ]
    if (options.isContentSearch && TARGET_CONTENT_SEARCH_ATTRIBUTES.includes(filterKey)) {
      return {
        ...newFilters,
        [`content_${filterKey}`]: newFilter
      }
    }
    return {
      ...newFilters,
      [filterKey]: newFilter
    };
  }, {});
};

export const mapReduxParamsToApiParams = (
  params: SearchParams,
  cleanLocation = false,
): SearchParams & { scopes: string } => {
  const {
    id,
    terms,
    researchable_element,
    search_scope,
    sort,
    from,
    to,
    page,
    per_page,
    required_networks,
    network_filters_operator,
    basic_filters,
    profile_genders,
    excluded_project_ids,
    excluded_list_ids,
    excluded_tag_ids,
    profile_type,
    exclude_favorites,
    global_statistics,

    profile_filters,
    instagram_filters,
    youtube_filters,
    tiktok_filters,
    twitter_filters,
    facebook_filters,
    snapchat_filters,
    visual_search,

    migrated,
  } = params;

  // To only send necessary parameters
  return {
    scopes: 'details,snas',
    id,
    terms,
    search_scope,
    researchable_element,
    sort,
    from,
    to,
    page,
    per_page,
    required_networks,
    network_filters_operator,
    basic_filters,
    profile_genders,
    excluded_project_ids,
    excluded_list_ids,
    excluded_tag_ids,
    exclude_favorites,
    profile_type,
    global_statistics,
    profile_filters,
    instagram_filters: cleanFilters(instagram_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    youtube_filters: cleanFilters(youtube_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    tiktok_filters: cleanFilters(tiktok_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    twitter_filters: cleanFilters(twitter_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    facebook_filters: cleanFilters(facebook_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    snapchat_filters: cleanFilters(snapchat_filters, { cleanLocation, isContentSearch: researchable_element === 'content' }),
    migrated: migrated || false,
    visual_search: visual_search || false,
  };
};
