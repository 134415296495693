import { get, put, post, remove } from 'api';
import { ApiPromise } from 'api/types';

import type {
  PaymentContact,
  CreatePaymentTaskParams,
  CreatePaymentTaskResponse,
  UpdatePaymentTaskParams,
  BatchUpdateStatusParams,
  FetchPaymentTaskParams,
  VerifyPaymentTaskParams,
  VerifyPaymentTaskResponse,
  FetchSinglePaymentTaskParams,
  FetchPaymentTaskResponse,
  FetchSinglePaymentTaskResponse,
  UpdatePaymentTaskResponse,
} from './campaignPayment.types';

// Payment contact
export const fetchPaymentContact: () => ApiPromise<PaymentContact> = () =>
  get({
    endpoint: `influence/v2/payment_contact`,
    catchErrors: [404],
  });

export const updatePaymentContact: (
  contact: PaymentContact,
) => ApiPromise<PaymentContact> = (contact) =>
  put({
    endpoint: `influence/v2/payment_contact`,
    params: contact,
    catchErrors: [422],
  });

// Phone verification
export const validatePhoneNumber: (phoneNumber: string) => ApiPromise<any> = (
  phoneNumber,
) =>
  put({
    endpoint: `influence/v2/payment_contact/phone_validation`,
    params: {
      phone: phoneNumber,
    },
    catchErrors: [422],
  });

export const verifyValidationCode: (
  verificationCode: string,
) => ApiPromise<VerifyPaymentTaskResponse> = (verificationCode) =>
  put({
    endpoint: `influence/v2/payment_contact/phone_validation/verify`,
    params: {
      code: verificationCode,
    },
    catchErrors: [422],
  });

// Payment task
export const fetchPaymentTask: (
  params: FetchPaymentTaskParams,
) => ApiPromise<FetchPaymentTaskResponse['response']> = ({
  campaign_id,
  ...params
}) =>
  get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts`,
    params,
    catchErrors: [404, 400],
  });

export const createPaymentTask: (
  params: CreatePaymentTaskParams,
) => ApiPromise<CreatePaymentTaskResponse> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return post({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/batch_create`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const fetchSinglePaymentTask: (
  params: FetchSinglePaymentTaskParams,
) => ApiPromise<FetchSinglePaymentTaskResponse> = (params) => {
  const { campaign_id, id, scopes } = params;
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    params: { scopes },
    catchErrors: [422],
  });
};

export const updatePaymentTask: (
  params: UpdatePaymentTaskParams,
) => ApiPromise<UpdatePaymentTaskResponse> = (params) => {
  const { campaign_id, id, ...apiParams } = params;
  return put({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const batchUpdateStatus: (
  params: BatchUpdateStatusParams,
) => ApiPromise<any> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return put({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/batch_update`,
    params: apiParams,
    catchErrors: [422],
  });
};

export const removePaymentTask: (params: {
  id: number;
  campaign_id;
}) => ApiPromise<any> = (params) => {
  const { id, campaign_id } = params;
  return remove({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/${id}`,
    catchErrors: [404],
  });
};

export const fetchAllowedCurrencies: (
  params: VerifyPaymentTaskParams,
) => ApiPromise<VerifyPaymentTaskResponse> = (params) => {
  const { campaign_id, ...apiParams } = params;
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/payouts/allowed_currencies`,
    params: apiParams,
    catchErrors: [422],
  });
};
