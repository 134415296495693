/* eslint-disable no-param-reassign */
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import * as api from 'api/organization';
import { ErrorResponse } from 'api/types';
import { RootState } from 'config/store';
import { mapStatuses } from 'mappers/user';
import { Status } from './campaignWorkflow.slice';

import { startLoading, stopLoading } from './utils';

export type Licence = {
  id: number;
  is_active: boolean;
  name: string;
  end_date: string;
  listening_name?: string;
  days?: number;
  used_days?: number;
};

export type LicenceDetails = {
  id: number;
  contact_id: number | null;
  is_active: boolean;
  is_moderate: boolean;
  licence_group_id: number;
  name: string;
  start_date: string;
  end_date: string;
  remaining_daytime?: string;
  days?: number;
  used_days?: number;
};

export type LicenceEdition = {
  id: number;
  contact_id: number | null;
};

export type Member = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  team?: {
    id: number;
    name: string;
    is_ghost_team: boolean;
  };
  role: string;
  teams_access_ids: number[],
  licences: Licence[];
  phone: string;
  position_title: string;
};

export type Team = {
  id: number;
  name: string;
  is_ghost_team?: boolean;
};

export type TeamAccess = {
  id: number;
  name: string;
  is_ghost_team: boolean;
  custom_lists: {
    id: number;
    name: string;
  }[];
  members: {
    id: number;
    first_name: string;
    last_name: string;
  }[];
  statuses: Array<Status>;
};

export type TeamMember = {
  core: {
    full_name: string;
    user_id: number;
    contact_id: number;
  };
};

export type MemberForm = Omit<Member, 'id' | 'licences'> & {
  id?: number | null;
};

// Initial State
type SliceState = {
  members: Member[];
  teams: Team[];
  licences: LicenceDetails[];
  loading: string[];
  teamsAccess: TeamAccess[];
};

const initialState: SliceState = {
  members: [],
  teams: [],
  licences: [],
  loading: [],
  teamsAccess: [],
};

// thunk
export const fetchMembers = createAsyncThunk<
  Member[],
  void,
  { state: RootState }
>('organization/fetchMembers', async () => {
  const response = await api.fetchMembers();
  return response && !response.error ? response : [];
});

export const saveMember = createAsyncThunk<
  void,
  { member: MemberForm; licencesModifications: LicenceEdition[], teamOrRoleChanged: boolean },
  { state: RootState }
>(
  'organization/saveMember',
  async (
    { member: memberInfos, licencesModifications, teamOrRoleChanged },
    { dispatch, rejectWithValue },
  ) => {
    const { id, team, ...accountInfos } = memberInfos;

    try {
      // save user
      const response = memberInfos.id
        ? await api.updateMember({ id, ...accountInfos })
        : await api.createMember(accountInfos);

      if (!response || response.error) throw response;

      const updatedMemberId = response.id;

      // add or update or delete account to team
      if (!memberInfos.id || teamOrRoleChanged) {
        await api.saveTeamRoleAndAccess({
          accountId: updatedMemberId,
          teamId: team?.is_ghost_team ? null : team?.id,
          role: accountInfos?.role,
          teamsAccess: accountInfos?.teams_access_ids || []
        });
      }

      // Manage licences
      if (licencesModifications?.length) {
        await dispatch(
          saveCompanyLicences({
            licences: licencesModifications,
            contact_id: updatedMemberId,
          }),
        );
      }

      void dispatch(fetchMembers());
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  },
);

export const fetchTeams = createAsyncThunk<Team[], void, { state: RootState }>(
  'organization/fetchTeams',
  async () => {
    const response = await api.fetchTeams();
    return response && !response.error ? response : [];
  },
);

export const saveTeam = createAsyncThunk<
  Partial<Team> & ErrorResponse,
  {
    team: Partial<Team>;
    onSuccess?: (team: Team) => void;
    onError?: (error: string | boolean) => void;
  },
  { state: RootState }
>(
  'organization/saveTeam',
  async ({ team, onSuccess, onError }, { dispatch }) => {
    const response = await api.saveTeam(team);
    if (response && !response.error) {
      await dispatch(fetchTeams());
      if (onSuccess) onSuccess(response);
    } else if (onError)
      onError(!response || response?.status?.toString() || '');
    return response;
  },
);

export const fetchLicences = createAsyncThunk<
  LicenceDetails[],
  void,
  { state: RootState }
>('organization/fetchLicences', async () => {
  const response = await api.fetchLicences();
  return response && !response.error ? response : [];
});

export const saveCompanyLicences = createAsyncThunk<
  void,
  { licences: LicenceEdition[]; contact_id: number },
  { state: RootState }
>('organization/saveTeam', ({ licences, contact_id }) => {
  return api.saveCompanyLicences({
    licences: licences?.map(l => ({
      ...l,
      contact_id: l.contact_id === 0 ? contact_id : l.contact_id,
    })),
  });
});

export const fetchTeamsAccess = createAsyncThunk<
  TeamAccess[],
  void,
  { state: RootState }
>('organization/fetchTeamsAccess', async () => {
  const response = await api.fetchTeamsAccess();
  return response && !response.error
    ? response.map(team => ({
        ...team,
        statuses: mapStatuses(team?.statuses),
      }))
    : [];
});

export const fetchTeamsMembers = createAsyncThunk<
  TeamMember[],
  { search?: string },
  { state: RootState }
>('organization/fetchTeamsMembers', async ({ search = "" }) => {
  const response = await api.fetchTeamsMembers({
    search,
    page: 0,
    per_page: 25,
  });
  return response && !response.error ? response.users : [];
});

// reducers
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // Example of custom action creator
    membersLoaded: (state, action: PayloadAction<Member[]>) => {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.members = action.payload;
      stopLoading(state, fetchMembers);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMembers.pending, state => {
        startLoading(state, fetchMembers);
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.members = action.payload;
        stopLoading(state, fetchMembers);
      });

    builder
      .addCase(saveMember.pending, state => {
        startLoading(state, saveMember);
      })
      .addCase(saveMember.fulfilled, state => {
        stopLoading(state, saveMember);
      })
      .addCase(saveMember.rejected, state => {
        stopLoading(state, saveMember);
      });

    builder
      .addCase(fetchTeams.pending, state => {
        startLoading(state, fetchTeams);
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        stopLoading(state, fetchTeams);
      });

    builder
      .addCase(fetchLicences.pending, state => {
        startLoading(state, fetchLicences);
      })
      .addCase(fetchLicences.fulfilled, (state, action) => {
        state.licences = action.payload;
        stopLoading(state, fetchLicences);
      });

    builder
      .addCase(fetchTeamsAccess.pending, state => {
        startLoading(state, fetchTeamsAccess);
      })
      .addCase(fetchTeamsAccess.fulfilled, (state, action) => {
        state.teamsAccess = action.payload;
        stopLoading(state, fetchTeamsAccess);
      });
  },
});

// action creators
export const { membersLoaded } = organizationSlice.actions;

export default organizationSlice.reducer;
